import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { bool } from 'prop-types';

import Toasts from '../../../components/Snackbar/Snackbar';
import Dashboard from '../../../views/Admin/Dashboard/Dashboard';
import { EventAdd } from '../../../views/Admin/Events/EventAdd/EventAdd';
import EventList from '../../../views/Admin/Events/EventList/EventList';
import MovingMission from '../../../views/Admin/MovingMission/MovingMission';
import { ArticleList } from '../../../views/Admin/News';
import ArticleAdd from '../../../views/Admin/News/ArticleAdd/ArticleAdd';
import { PermissionList } from '../../../views/Admin/Permissions/PermissionList/PermissionList';
import { TeacherAdd } from '../../../views/Admin/Teachers/TeacherAdd/TeacherAdd';
import { TeacherList } from '../../../views/Admin/Teachers/TeacherList/TeacherList';
import TrainingAdd from '../../../views/Admin/Trainings/TrainingAdd/TrainingAdd';
import TrainingList from '../../../views/Admin/Trainings/TrainingList/TrainingList';

import Navigation from './Navigation/Navigation';
import Sidebar from './Sidebar/Sidebar';

import './AdminLayout.scss';

const AdminLayout = () => {
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);

	const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

	return (
		<div className={`admin ${isSidebarOpen ? 'sidebar-open' : ''}`}>
			<Navigation toggleSidebar={toggleSidebar} />
			<Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
			<main className='admin-content'>
				<Routes>
					<Route path='/*' index element={<Dashboard />} />

					<Route path='nieuws' element={<ArticleList />} />
					<Route
						path='nieuws/:nieuw-artikel'
						element={<ArticleAdd />}
					/>

					<Route path='opleidingen' element={<TrainingList />} />
					<Route
						path='opleidingen/:nieuwe-opleiding'
						element={<TrainingAdd />}
					/>

					<Route path='evenementen' element={<EventList />} />
					<Route
						path='evenementen/:nieuw-evenement'
						element={<EventAdd />}
					/>
					<Route
						path='evenementen/:nieuw-evenement/:slug'
						element={<EventAdd />}
					/>

					<Route path='permissies' element={<PermissionList />} />
					<Route path='lesgevers' element={<TeacherList />} />
					<Route path='moving-mission' element={<MovingMission />} />
					<Route
						path='lesgevers/:nieuwe-lesgever'
						element={<TeacherAdd />}
					/>
				</Routes>
			</main>
			<Toasts />
		</div>
	);
};

AdminLayout.propTypes = {
	online: bool,
};

export default AdminLayout;
