import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { InteractionType } from '@azure/msal-browser';
import { useIsAuthenticated, useMsalAuthentication } from '@azure/msal-react';

import AdminLayout from '../../../components/Layouts/AdminLayout/AdminLayout';
import Loading from '../../../components/Loading/Loading';
import { loadProfile } from '../../../core/store/authentication/auth.actions';
import { Login } from '../../../views';
import ProtectedRoutes from '../ProtectedRoutes/ProtectedRoutes';
import UnprotectedRoutes from '../UnprotectedRoutes/UnprotectedRoutes';

const AdminRouter = () => {
	useMsalAuthentication(InteractionType.Redirect);
	const isAuthenticated = useIsAuthenticated();
	const { authLoading } = useSelector(state => state.authReducer);
	const { meInfoFetched } = useSelector(state => state.authReducer);

	const { loginTries } = useSelector(state => state.authReducer);

	if (loginTries >= 5) {
		throw new Error();
	}

	useEffect(() => {
		if (isAuthenticated && !meInfoFetched) {
			loadProfile();
		}
	}, [isAuthenticated, meInfoFetched]);

	return (
		<Routes>
			<Route element={<UnprotectedRoutes loggedIn={isAuthenticated} />}>
				<Route path='/login' element={<Login />} />
			</Route>

			{isAuthenticated ? (
				<Route element={<ProtectedRoutes loggedIn={isAuthenticated} />}>
					<Route
						path='/*'
						element={
							authLoading ? (
								<Loading large centered />
							) : (
								<AdminLayout />
							)
						}
					/>
				</Route>
			) : null}
		</Routes>
	);
};

export default AdminRouter;
