import {
	any,
	array,
	bool,
	func,
	number,
	object,
	oneOfType,
	string,
} from 'prop-types';

import UiInput from '../UiInput/UiInput';
import useFormInput from '../useFormInput';

const InputField = ({
	type,
	name,
	label,
	placeholder,
	icon,
	buttonIcon,
	description,
	value,
	required,
	readOnly,
	disabled,
	autocomplete,
	validations,
	validate,
	errors,
	onChange,
	className,
	onKeyDown,
}) => {
	const { touched, focused, id, ...handlers } = useFormInput({
		name,
		errors,
		validate,
		onChange,
		validations,
	});

	return (
		<UiInput
			hasValue={value ? value.length > 0 : null}
			errorMsgs={touched ? errors : []}
			label={label}
			required={required}
			description={description}
			icon={icon}
			buttonIcon={buttonIcon}
			className={className}
			focused={focused}
			disabled={disabled}
			id={id}>
			<input
				type={type}
				id={id}
				value={value ?? null}
				placeholder={`${placeholder}${
					placeholder && required && !label ? ' *' : ''
				}`}
				{...handlers}
				autoComplete={autocomplete ? autocomplete : 'off'}
				readOnly={readOnly}
				disabled={disabled}
				onKeyDown={onKeyDown}
			/>
		</UiInput>
	);
};

InputField.defaultProps = {
	type: 'text',
	label: '',
	placeholder: '',
	description: '',
	required: false,
	readOnly: false,
	disabled: false,
	autocomplete: false,
	validations: [],
	errors: [],
	value: '',
};

InputField.propTypes = {
	type: string,
	name: string.isRequired,
	label: string,
	placeholder: string,
	description: string,
	value: oneOfType([string, number]),
	required: bool,
	readOnly: bool,
	disabled: bool,
	icon: object,
	buttonIcon: object,
	autocomplete: any,
	validations: array,
	errors: array,
	onChange: func.isRequired,
	validate: func.isRequired,
};

export default InputField;
