import { useState } from 'react';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './PostItem.scss';

const PostItem = ({ date, duration, activity, activityIcon }) => {
	const [showDetails, setShowDetails] = useState(false);

	const toggleDetails = e => {
		e.stopPropagation(); // Prevent event bubbling
		setShowDetails(!showDetails);
	};

	return (
		<div className='post-item'>
			<div className='post-main-content' onClick={toggleDetails}>
				<FontAwesomeIcon
					icon={activityIcon}
					className='activity-icon'
				/>
				<span className='activity-name'>
					{activity.charAt(0).toUpperCase() + activity.slice(1)}
				</span>
				<span>{`${date} - ${duration} mins`}</span>
				<FontAwesomeIcon
					icon={faArrowRight}
					className={`arrow-icon ${showDetails ? 'expanded' : ''}`}
				/>
			</div>
			{showDetails ? (
				<div className='download-link'>Download Collection</div>
			) : null}
		</div>
	);
};

export default PostItem;
