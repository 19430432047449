import Modal from '../../../components/Modal/Modal';

export const ListItemModal = ({ modal, setModal }) => {
	return (
		<Modal
			show={modal != null}
			close={() => setModal(undefined)}
			title={
				modal === 'delete'
					? 'Ben je zeker dat je dit item wilt verwijderen?'
					: modal === 'publish'
						? 'Ben je zeker dat je dit item wilt publiceren?'
						: modal === 'unpublish'
							? 'Ben je zeker dat je dit item niet meer wilt publiceren?'
							: 'Er is iets fout gelopen'
			}>
			<section className='modal-body'>
				{modal === 'delete' ? (
					<button className='btn btn--primary'>verwijderen</button>
				) : modal === 'unpublish' ? (
					<button className='btn btn--primary'>
						Niet meer publiceren
					</button>
				) : modal === 'publish' ? (
					<button className='btn btn--primary'>Publiceren</button>
				) : (
					<p>Er is iets fout gegaan.</p>
				)}
			</section>
		</Modal>
	);
};
