import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import AddNewItemPage from '../../../../components/Layouts/AdminLayout/AddNewItemPage/AddNewItemPage';
import { changeArrayPropertyNames } from '../../../../core/services/parse/index';
import { loadTags } from '../../../../core/store/tags/tag.actions';

export const EventAdd = () => {
	const [actie, setActie] = useState('toevoegen');

	const [title, setTitle] = useState('');
	const [organisatoren, setOrganisatoren] = useState([]);
	const [geselecteerdeMedewerkers, setGeselecteerdeMedewerkers] = useState(
		[],
	);
	const [description, setDescription] = useState();
	const [documents, setDocuments] = useState([]);
	const [volledigeDatum, setVolledigetDatum] = useState();
	const [locatie, setLocatie] = useState(null);
	const [limietOpInschrijvingen, setLimietOpInschrijvingen] = useState(10);
	const [links, setLinks] = useState([]);

	const { tags } = useSelector(state => state.tagReducer);
	const params = useParams();
	const slug = `http://localhost:8080/api/events/${params.slug}`;
	const config = {
		headers: {
			Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjgwODAvYXBpL2xvZ2luIiwiaWF0IjoxNjc2Mzg1Njc4LCJuYmYiOjE2NzYzODU2NzgsImp0aSI6InBuVGZ6cU5Fd1hrekJWaTIiLCJzdWIiOiIxOTMiLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3IiwiZWZmZWN5VXNlcklkIjpudWxsLCJpc0FkbWluIjp0cnVlfQ.mVqGll8Q3ftnENmH-nvHDbpuw3AVa4t7SCXOzUYnCYI`,
		},
	};
	function getEventData() {
		axios
			.get(slug, config)
			.then(function (response) {
				setTitle(response.data.data.name);
				setLocatie(response.data.data.location);
			})
			.catch(console.log);
		params.slug === undefined
			? setActie('toevoegen')
			: setActie('bewerken');
	}
	//todo fix dependencies
	useEffect(() => {
		if (params.slug !== undefined) {
			getEventData();
		}
		loadTags('events');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<AddNewItemPage
			type='events'
			tags={changeArrayPropertyNames(tags.events.data)}
			pageTitle={`Evenement ${actie}`}
			title={title}
			setTitle={setTitle}
			organisatoren={organisatoren}
			setOrganisatoren={setOrganisatoren}
			geselecteerdeMedewerkers={geselecteerdeMedewerkers}
			setGeselecteerdeMedewerkers={setGeselecteerdeMedewerkers}
			volledigeDatum={volledigeDatum}
			setVolledigetDatum={setVolledigetDatum}
			locatie={locatie}
			setLocatie={setLocatie}
			uploadedDocuments={documents}
			uploadNewDocuments={setDocuments}
			links={links}
			setLinks={setLinks}
			limietOpInschrijvingen={limietOpInschrijvingen}
			setLimietOpInschrijvingen={setLimietOpInschrijvingen}
			optieVoorOpleidingsUren={true}
			optieVoorBroodjes={false}
			tekst={description}
			setTekst={setDescription}
		/>
	);
};
