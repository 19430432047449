export const Permissions_example = [
	{
		id: 1,
		name: 'mainfeed_see',
		created_at: '2021-01-07 10:01:07',
		updated_at: '2021-01-07 10:01:07',
		display_name: 'Bekijk main-feed',
		description:
			'Gebruikers met deze permissie kunnen de main-feed bekijken',
	},
	{
		id: 2,
		name: 'subfeed_see',
		created_at: '2021-01-07 10:01:07',
		updated_at: '2021-01-07 10:01:07',
		display_name: 'Bekijk sub-feed',
		description:
			'Gebruikers met deze permissie kunnen de sub-feed bekijken',
	},
	{
		id: 3,
		name: 'users_see_overview',
		created_at: '2021-01-07 10:01:07',
		updated_at: '2021-01-07 10:01:07',
		display_name: 'Bekijk gebruiker overzicht',
		description:
			'Gebruikers met deze permissie kunnen het gebruikersoverzicht bekijken',
	},
	{
		id: 4,
		name: 'users_see_detail',
		created_at: '2021-01-07 10:01:07',
		updated_at: '2021-01-07 10:01:07',
		display_name: 'Bekijk gebruiker detail',
		description:
			'Gebruikers met deze permissie kunnen gebruikerdetails bekijken',
	},
	{
		id: 5,
		name: 'users_see_full_detail',
		created_at: '2021-01-07 10:01:07',
		updated_at: '2021-01-07 10:01:07',
		display_name: 'Bekijk volledige gebruiker detail',
		description:
			'Gebruikers met deze permissie kunnen volledige gebruikerdatils bekijken (incl. telefoonnummer, opleidingen, ...)',
	},
	{
		id: 6,
		name: 'users_reset_account',
		created_at: '2021-01-07 10:01:07',
		updated_at: '2021-01-07 10:01:07',
		display_name: 'Reset gebruiker account',
		description:
			'Gebruikers met deze permissie kunnen een gebruikersaccount herstellen',
	},
	{
		id: 7,
		name: 'users_see_notifications',
		created_at: '2021-01-07 10:01:07',
		updated_at: '2021-01-07 10:01:07',
		display_name: 'Bekijk notificaties',
		description:
			'Gebruikers met deze permissie kunnen notificaties bekijken',
	},
	{
		id: 8,
		name: 'claims_illnesses_see',
		created_at: '2021-01-07 10:01:07',
		updated_at: '2021-01-07 10:01:07',
		display_name: 'Bekijk ziektemeldingen',
		description:
			'Gebruikers met deze permissie kunnen ziektemeldingen bekijken',
	},
	{
		id: 9,
		name: 'claims_illnesses_add',
		created_at: '2021-01-07 10:01:07',
		updated_at: '2021-01-07 10:01:07',
		display_name: 'Ziektemeldingen toevoegen',
		description:
			'Gebruikers met deze permissie kunnen de ziektemeldingen toevoegen',
	},
	{
		id: 10,
		name: 'claims_damages_see',
		created_at: '2021-01-07 10:01:07',
		updated_at: '2021-01-07 10:01:07',
		display_name: 'Bekijke schademeldingen',
		description:
			'Gebruikers met deze permissie kunnen schademeldingen bekijken',
	},
	{
		id: 11,
		name: 'claims_damages_add',
		created_at: '2021-01-07 10:01:07',
		updated_at: '2021-01-07 10:01:07',
		display_name: 'Schademeldingen toevoegen',
		description:
			'Gebruikers met deze permissie kunnen schademeldingen toevoegen',
	},
	{
		id: 12,
		name: 'documents_see',
		created_at: '2021-01-07 10:01:07',
		updated_at: '2021-01-07 10:01:07',
		display_name: 'Documenten bekijken',
		description:
			'Gebruikers met deze permissie kunnen documenten bekijken en downloaden',
	},
	{
		id: 13,
		name: 'news_see_published',
		created_at: '2021-01-07 10:01:07',
		updated_at: '2021-01-07 10:01:07',
		display_name: 'Gepubliceerd nieuws bekijken',
		description:
			'Gebruikers met deze permissie kunnen gepubliceerde nieuwsberichten bekijken',
	},
	{
		id: 14,
		name: 'news_see_unpublished',
		created_at: '2021-01-07 10:01:08',
		updated_at: '2021-01-07 10:01:08',
		display_name: 'Ongepubliceerd nieuws bekijken',
		description:
			'Gebruikers met deze permissie kunnen ongepubliceerde nieuwsberichten bekijken',
	},
	{
		id: 15,
		name: 'news_add',
		created_at: '2021-01-07 10:01:08',
		updated_at: '2021-01-07 10:01:08',
		display_name: 'Nieuws toevoegen',
		description:
			'Gebruikers met deze permissie kunnen nieuwsberichten toevoegen',
	},
	{
		id: 16,
		name: 'news_update',
		created_at: '2021-01-07 10:01:08',
		updated_at: '2021-01-07 10:01:08',
		display_name: 'Nieuws bewerken',
		description:
			'Gebruikers met deze permissie kunnen nieuwsberichten bewerken',
	},
	{
		id: 17,
		name: 'news_publish',
		created_at: '2021-01-07 10:01:08',
		updated_at: '2021-01-07 10:01:08',
		display_name: 'Nieuws publiceren',
		description:
			'Gebruikers met deze permissie kunnen nieuwsberichten publiceren',
	},
	{
		id: 18,
		name: 'news_delete',
		created_at: '2021-01-07 10:01:08',
		updated_at: '2021-01-07 10:01:08',
		display_name: 'Nieuws verwijderen',
		description:
			'Gebruikers met deze permissie kunnen nieuwsberichten verwijderen',
	},
	{
		id: 19,
		name: 'news_pin',
		created_at: '2021-01-07 10:01:08',
		updated_at: '2021-01-07 10:01:08',
		display_name: 'Nieuws pinnen',
		description:
			'Gebruikers met deze permissie kunnen nieuwsberichten vastpinnen aan hun gebruikersaccount',
	},
	{
		id: 20,
		name: 'events_see_published',
		created_at: '2021-01-07 10:01:08',
		updated_at: '2021-01-07 10:01:08',
		display_name: 'Gepubliceerde evenementen bekijken',
		description:
			'Gebruikers met deze permissie kunnen gepubliceerde evenementen bekijken',
	},
	{
		id: 21,
		name: 'events_see_unpublished',
		created_at: '2021-01-07 10:01:08',
		updated_at: '2021-01-07 10:01:08',
		display_name: 'Ongepubliceerde evenementen bekijken',
		description:
			'Gebruikers met deze permissie kunnen ongepubliceerde evenementen bekijken',
	},
	{
		id: 22,
		name: 'events_add',
		created_at: '2021-01-07 10:01:08',
		updated_at: '2021-01-07 10:01:08',
		display_name: 'Evenementen toevoegen',
		description:
			'Gebruikers met deze permissie kunnen evenementen toevoegen',
	},
	{
		id: 23,
		name: 'events_update',
		created_at: '2021-01-07 10:01:08',
		updated_at: '2021-01-07 10:01:08',
		display_name: 'Evenementen bewerken',
		description:
			'Gebruikers met deze permissie kunnen evenementen bewerken',
	},
	{
		id: 24,
		name: 'events_publish',
		created_at: '2021-01-07 10:01:08',
		updated_at: '2021-01-07 10:01:08',
		display_name: 'Evenementen publiceren',
		description:
			'Gebruikers met deze permissie kunnen evenementen publiceren',
	},
	{
		id: 25,
		name: 'events_delete',
		created_at: '2021-01-07 10:01:08',
		updated_at: '2021-01-07 10:01:08',
		display_name: 'Evenementen verwijderen',
		description:
			'Gebruikers met deze permissie kunnen evenementen verwijderen',
	},
	{
		id: 26,
		name: 'trainings_see_published',
		created_at: '2021-01-07 10:01:08',
		updated_at: '2021-01-07 10:01:08',
		display_name: 'Gepubliceerde opleidingen bekijken',
		description:
			'Gebruikers met deze permissie kunnen gepubliceerde opleidingen bekijken',
	},
	{
		id: 27,
		name: 'trainings_see_unpublished',
		created_at: '2021-01-07 10:01:08',
		updated_at: '2021-01-07 10:01:08',
		display_name: 'Ongepubliceerde opleidingen bekijken',
		description:
			'Gebruikers met deze permissie kunnen ongepubliceerde opleidingen bekijken',
	},
	{
		id: 28,
		name: 'trainings_add',
		created_at: '2021-01-07 10:01:08',
		updated_at: '2021-01-07 10:01:08',
		display_name: 'Opleidingen toevoegen',
		description:
			'Gebruikers met deze permissie kunnen opleidingen toevoegen',
	},
	{
		id: 29,
		name: 'trainings_update',
		created_at: '2021-01-07 10:01:08',
		updated_at: '2021-01-07 10:01:08',
		display_name: 'Opleidingen bewerken',
		description:
			'Gebruikers met deze permissie kunnen opleidingen bewerken',
	},
	{
		id: 30,
		name: 'trainings_publish',
		created_at: '2021-01-07 10:01:08',
		updated_at: '2021-01-07 10:01:08',
		display_name: 'Opleidingen publiceren',
		description:
			'Gebruikers met deze permissie kunnen opleidingen publiceren',
	},
	{
		id: 31,
		name: 'trainings_delete',
		created_at: '2021-01-07 10:01:08',
		updated_at: '2021-01-07 10:01:08',
		display_name: 'Opleidingen verwijderen',
		description:
			'Gebruikers met deze permissie kunnen opleidingen verwijderen',
	},
	{
		id: 32,
		name: 'comments_see',
		created_at: '2021-01-07 10:01:08',
		updated_at: '2021-01-07 10:01:08',
		display_name: 'Comments bekijken',
		description: 'Gebruikers met deze permissie kunnen comments bekijken',
	},
	{
		id: 33,
		name: 'comments_add',
		created_at: '2021-01-07 10:01:08',
		updated_at: '2021-01-07 10:01:08',
		display_name: 'Comments toevoegen',
		description: 'Gebruikers met deze permissie kunnen comments toevoegen',
	},
	{
		id: 34,
		name: 'comments_mention',
		created_at: '2021-01-07 10:01:08',
		updated_at: '2021-01-07 10:01:08',
		display_name: 'Consultants vernoemen',
		description:
			'Gebruikers met deze permissie kunnen consultants vernoemen bij comments',
	},
	{
		id: 35,
		name: 'stickers_add',
		created_at: '2021-01-07 10:01:08',
		updated_at: '2021-01-07 10:01:08',
		display_name: 'Stickers toevoegen',
		description: 'Gebruikers met deze permissie kunnen stickers toevoegen',
	},
	{
		id: 36,
		name: 'claims_illnesses_admin',
		created_at: '2021-01-07 10:01:08',
		updated_at: '2021-01-07 10:01:08',
		display_name: 'Ziektemeldingen beheren',
		description:
			'Gebruikers met deze permissie kunnen ziektemeldingen beheren',
	},
	{
		id: 37,
		name: 'claims_damages_admin',
		created_at: '2021-01-07 10:01:08',
		updated_at: '2021-01-07 10:01:08',
		display_name: 'Schademeldingen beheren',
		description:
			'Gebruikers met deze permissie kunnen schademeldingen beheren',
	},
	{
		id: 38,
		name: 'sandwiches_admin',
		created_at: '2021-01-07 10:01:09',
		updated_at: '2021-01-07 10:01:09',
		display_name: 'Broodjes admin beheren',
		description:
			'Gebruikers met deze permissie kunnen de broodjeslijst beheren',
	},
	{
		id: 39,
		name: 'permissions_admin',
		created_at: '2021-01-07 10:01:09',
		updated_at: '2021-01-07 10:01:09',
		display_name: 'Permissies beheren',
		description: 'Gebruikers met deze permissie kunnen permissies beheren',
	},
	{
		id: 40,
		name: 'users_see_timesheet',
		created_at: '2021-01-07 00:00:00',
		updated_at: '2021-01-07 00:00:00',
		display_name: 'Timesheets beheren',
		description:
			'Gebruikers met deze permissie kunnen timesheets van andere gebruikers bekijken en beheren',
	},
	{
		id: 41,
		name: 'events_see_results',
		created_at: '2021-06-25 00:00:00',
		updated_at: '2021-06-25 00:00:00',
		display_name: 'Bekijken van evenement-resultaten',
		description:
			'Gebruikers met deze permissie kunnen inschrijvingen van evenementen bekijken en beheren',
	},
	{
		id: 42,
		name: 'news_see_results',
		created_at: '2021-06-25 00:00:00',
		updated_at: '2021-06-25 00:00:00',
		display_name: 'Bekijken van nieuws-formulieren',
		description:
			'Gebruikers met deze permissie kunnen formulieren van nieuwsberichten bekijken en beheren',
	},
	{
		id: 43,
		name: 'trainings_see_results',
		created_at: '2021-06-25 00:00:00',
		updated_at: '2021-06-25 00:00:00',
		display_name: 'Bekijken van opleiding-resultaten',
		description:
			'Gebruikers met deze permissie kunnen inschrijvingen van opleidingen bekijken en beheren',
	},
	{
		id: 44,
		name: 'instructors_admin',
		created_at: '2022-02-24 09:09:26',
		updated_at: '2022-02-24 09:09:26',
		display_name: 'Lesgevers admin beheren',
		description:
			'Gebruikers met deze permissie kunnen de lesgevers beheren',
	},
	{
		id: 45,
		name: 'invitations_see',
		created_at: '2022-04-14 00:00:00',
		updated_at: '2022-04-14 00:00:00',
		display_name: 'Uitnodigingen bekijken',
		description:
			'Gebruikers met deze permissie kunnen uitnodigingen van evenementen of opleidingen bekijken en beheren',
	},
];
