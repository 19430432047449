import { ACTION_TYPES } from '../../../core/constants/action.constants/action.constants';
import { dispatchAction } from '../../../core/services/actions';
import { store } from '../store.config';

export const loadTrainings = () => {
	const state = store.getState();
	const { trainingsLoading } = state.trainingReducer;
	if (!trainingsLoading) {
		dispatchAction({
			type: ACTION_TYPES.TRAINING_FEEDS_LOAD,
			payload: { trainingsLoading: true },
			meta: {
				action: {
					effect: {
						url: `/trainings`,
						method: 'get',
					},
					commit: { type: ACTION_TYPES.TRAINING_FEEDS_LOAD_COMMIT },
					rollback: {
						type: ACTION_TYPES.TRAINING_FEEDS_LOAD,
						payload: { trainingsLoading: false },
					},
				},
			},
		});
	}
};

export const loadTraining = slug => {
	dispatchAction({
		type: ACTION_TYPES.TRAINING_LOAD,
		payload: { trainingLoading: true },
		meta: {
			action: {
				effect: {
					url: `/trainings/${slug}`,
					method: 'get',
				},
				commit: { type: ACTION_TYPES.TRAINING_LOAD_COMMIT },
				rollback: {
					type: ACTION_TYPES.TRAINING_LOAD,
				},
			},
		},
	});
};

export const postForm = body => {
	dispatchAction({
		meta: {
			action: {
				effect: {
					url: '/forms',
					method: 'post',
					body,
				},
				rollback: {},
			},
		},
	});
};
