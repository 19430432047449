import { ACTION_TYPES } from '../../../core/constants/action.constants/action.constants';

let defaultState = {
	confirmed: null,
	request: null,
};

const loadInviteCommit = (state, { data }) => data;

export const inviteReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.INVITE_LOAD_COMMIT:
			return loadInviteCommit(state, payload);
		default:
			return state;
	}
};
