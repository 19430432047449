import { combineReducers } from 'redux';

import { applicationReducer } from './application/application.reducer';
import { authReducer } from './authentication/auth.reducer';
import { cocesReducer } from './coces/coces.reducer';
import { departmentsReducer } from './departments/departments.reducer';
import { eventReducer } from './events/events.reducer';
import { inviteReducer } from './invites/invite.reducer';
import { mainFeedReducer } from './mainFeed/mainFeed.reducer';
import { subFeedReducer } from './subFeed/subFeed.reducer';
import { tagReducer } from './tags/tag.reducer';
import { toppingReducer } from './toppings/topping.reducer';
import { trainingReducer } from './trainings/trainings.reducer';
import { usersReducer } from './users/users.reducer';

export default combineReducers({
	applicationReducer,
	authReducer,
	cocesReducer,
	departmentsReducer,
	eventReducer,
	mainFeedReducer,
	subFeedReducer,
	tagReducer,
	trainingReducer,
	usersReducer,
	toppingReducer,
	inviteReducer,
});
