import { executeAxios } from 'core/services/actions';
import { handleAlert } from 'core/services/actions/action.service';

export const getMovingMissionData = async () => {
	try {
		const response = await executeAxios({
			url: '/admin/moving-mission/1',
		});
		const { data } = response.data;
		console.log('Data received from getMovingMissionData:', data); // Added console log
		return data;
	} catch (error) {
		handleAlert('error', 'Er is iets misgegaan');
		console.error('error', error);
		return null;
	}
};

export const getEmployeeData = async userId => {
	try {
		const response = await executeAxios({
			url: `/admin/moving-mission/1/user/${userId}`,
			method: 'GET',
		});
		const { data } = response.data;
		return data;
	} catch (error) {
		handleAlert('error', 'Er is iets misgegaan');
		console.error('error', error);
		return null; // Returning null in case of an error
	}
};
export const getSportData = async () => {
	try {
		const response = await executeAxios({
			url: '/moving-mission/types',
			method: 'GET',
		});
		const { data } = response.data;
		return data;
	} catch (error) {
		handleAlert('error', 'Something went wrong');
		console.error('error', error);
		return null; // Returning null in case of an error
	}
};
export const getCompanyActivityData = async (startDate, endDate) => {
	try {
		const response = await executeAxios({
			url: '/admin/moving-mission/1',
			method: 'GET',
			params: {
				startDate,
				endDate,
			},
		});
		console.log('Response:', response);
		const { data } = response.data;
		return data;
	} catch (error) {
		handleAlert('error', 'Something went wrong');
		console.error('error', error);
		return null; // Returning null in case of an error
	}
};

export const getSportDataById = async sportId => {
	try {
		const response = await executeAxios({
			url: `/admin/moving-mission/1/sport/${sportId}`,
			method: 'GET',
		});
		const { data } = response.data;
		return data;
	} catch (error) {
		handleAlert('error', 'Something went wrong');
		console.error('error', error);
		return null; // Returning null in case of an error
	}
};
