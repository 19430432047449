import './modals.scss';

export const InputHourModal = ({
	uur,
	setUur,
	isEenHeleDag,
	setIsEenHeleDag,
}) => {
	return (
		<section className='hour-container'>
			<label>
				Duurt het een hele dag ? <span>(9-5)</span>
			</label>
			<input
				type='checkbox'
				value={isEenHeleDag}
				onChange={() => setIsEenHeleDag(!isEenHeleDag)}
			/>
			{isEenHeleDag ? (
				<input
					type='time'
					value={uur}
					onChange={e => setUur(e.target.value)}
					disabled
				/>
			) : (
				<input
					type='time'
					value={uur}
					onChange={e => setUur(e.target.value)}
				/>
			)}
		</section>
	);
};
