import { ACTION_TYPES } from '../../../core/constants/action.constants/action.constants';

let defaultState = {
	mainFeeds: [],
	mainFeedsNextPage: 1,
	mainFeedHasNextPage: true,
	mainFeedIsNextPageLoading: false,
};

const loadMainFeeds = (state, { mainFeedLoading }) => ({
	...state,
	mainFeedIsNextPageLoading: mainFeedLoading,
});

const loadMainFeedsCommit = (state, { data }) => {
	const hasNextPage = data.currentPage < data.lastPage;
	return {
		...state,
		mainFeeds: [
			...state.mainFeeds,
			...data.data
				.filter(x => !state.mainFeeds.find(y => x.id === y.id))
				.map(x => ({
					...x,
					attachments: x?.attachments?.map(m => ({
						type: 'attachments',
						uuid: m.id,
					})),
				})),
		],
		mainFeedsNextPage: hasNextPage ? data.currentPage + 1 : null,
		mainFeedHasNextPage: hasNextPage,
		mainFeedIsNextPageLoading: false,
	};
};

export const mainFeedReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.MAIN_FEEDS_LOAD:
			return loadMainFeeds(state, payload);
		case ACTION_TYPES.MAIN_FEEDS_LOAD_COMMIT:
			return loadMainFeedsCommit(state, payload);
		default:
			return state;
	}
};
