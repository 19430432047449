import { useEffect, useState } from 'react';

export default function useInputField({
	name,
	validate,
	errors,
	onChange,
	validations,
}) {
	const [touched, setTouched] = useState(false);
	const [focused, setFocused] = useState(false);

	const id = name
		? name
				.toLowerCase()
				.split(' ')
				.map(word => word.charAt(0).toUpperCase() + word.slice(1))
				.join('')
		: Math.random().toString(36).substring(2, 15);

	const handleKeyUp = () => {
		validate(validations);
		if (errors.length === 0) {
			setTouched(true);
		}
	};

	const handleFocus = () => setFocused(true);

	const handleBlur = () => {
		setFocused(false);
		setTouched(true);
		validate(validations);
	};

	const handleChange = e => {
		onChange(e.target.value);
	};

	useEffect(() => {
		validate(validations);
	}, []); // eslint-disable-line

	return {
		touched,
		focused,
		id,
		onBlur: handleBlur,
		onFocus: handleFocus,
		onKeyUp: handleKeyUp,
		onChange: handleChange,
	};
}
