import { array, bool, func, number, oneOfType, string } from 'prop-types';

import useFormInput from '../useFormInput';

import './InputCheckbox.scss';

const InputCheckbox = ({
	type,
	name,
	label,
	description,
	value,
	required,
	readOnly,
	disabled,
	validations,
	validate,
	errors,
	onChange,
	className,
}) => {
	const { touched, focused, id, ...handlers } = useFormInput({
		name,
		errors,
		onChange,
		validate,
		validations,
	});

	return (
		<div
			className={`form-checkbox${
				focused ? ' form-checkbox--focused' : ''
			}  ${className ? className : ''}`}>
			<div
				className={`form-checkbox__control${
					errors.length === 0 || !touched ? '' : ' is-invalid'
				}`}>
				<input
					name={name}
					type={type}
					id={id}
					checked={value}
					{...handlers}
					onChange={() => (readOnly ? null : onChange(!value))}
					readOnly={readOnly}
					disabled={disabled}
				/>

				<label htmlFor={id}>
					{label} {required ? <code>*</code> : null}
				</label>
			</div>

			<div className='description'>{description}</div>

			{errors.length > 0 && touched ? (
				<small className='invalid-feedback'>{errors[0]}</small>
			) : null}
		</div>
	);
};

InputCheckbox.defaultProps = {
	type: 'checkbox',
	label: '',
	description: '',
	value: false,
	required: false,
	readOnly: false,
	disabled: false,
	validations: [],
	errors: [],
};

InputCheckbox.propTypes = {
	type: string.isRequired,
	name: string.isRequired,
	label: string,
	description: string,
	value: oneOfType([string, bool, number]),
	required: bool,
	readOnly: bool,
	disabled: bool,
	validations: array,
	errors: array,
	onChange: func.isRequired,
	validate: func.isRequired,
	className: string,
};

export default InputCheckbox;
