import axios from 'axios';
import axiosRetry from 'axios-retry';

import { ACTION_TYPES } from '../../../core/constants/action.constants/action.constants';
import snackbarFactories from '../../../core/factories/snackbar.factories';
import { store } from '../../../core/store/store.config';
import { db } from '../../../dexie-db';
import { getToken } from '../auth/getToken';
import { requestMessageService } from '../message';

export const dispatchAction = ({ type, payload, meta }) => {
	handleDispatch(type, payload)
		.then(() => {
			return handleMeta(type, payload, meta);
		})
		.then(succeeded => {
			if (succeeded) handleFollowup(meta.followup);
		})
		.finally(() => {
			const remember = localStorage.getItem('rememberMe');
			if (remember) persistStore();
		});
};

const handleDispatch = (type, payload) => {
	return new Promise(resolver => {
		if (type) {
			store.dispatch({
				type: type,
				payload: payload,
			});
		}

		return resolver();
	});
};

const handleMeta = async (type, payload, meta) => {
	if (meta && meta.action) {
		const online = window.navigator.onLine;

		if (online) {
			return await executeAction(type, payload, meta);
		} else {
			return await storeActionForExecution(type, payload, meta);
		}
	}
};

const executeAction = async (type, payload, meta) => {
	const token = await getToken();
	const online = window.navigator.onLine;
	const {
		commit = {},
		rollback,
		effect: { method = 'get', url, body, addHeader = true, retry = true },
	} = meta.action;

	let headers = addHeader ? { Authorization: `Bearer ${token}` } : {};

	if (retry) {
		axiosRetry(axios, {
			retries: 5,
			retryDelay: retryCount => {
				handleAlert(
					'warning',
					`Er ging iets fout. We proberen het nog ${
						6 - retryCount
					} keer, binnen ${retryCount * 2} seconden.`,
					retryCount * 2000,
				);

				return retryCount * 2000;
			},
			retryCondition: error => {
				return error.response.status === 429;
			},
		});
	}

	return axios({
		url,
		method,
		headers,
		baseURL: process.env.REACT_APP_NODE_API_URL,
		data: body,
	})
		.then(({ data }) => {
			return handleDispatch(
				commit.type,
				commit.payload ? { ...commit.payload, data } : data,
			);
		})
		.then(() => {
			return true;
		})
		.catch(error => {
			if (retry && !online) {
				storeActionForExecution(type, payload, meta);
			} else {
				const message = requestMessageService(type, error);
				handleRollback(rollback, true, true, {
					error: message,
					type: 'error',
				});
			}

			return false;
		});
};

const storeActionForExecution = (type, payload, meta) => {
	handleRollback(meta.action.rollback, false);

	if (type !== ACTION_TYPES.MEDIA_LOAD_BLOB) {
		return db.dispatchActions
			.add({ type, payload, meta })
			.then(() => {
				handleAlert(
					'info',
					'Je actie is bewaard en zal worden uitgevoerd wanneer je weer verbonden bent met het netwerk.',
				);
			})
			.catch(() => {
				handleAlert(
					'error',
					'Je actie kon niet worden bewaard. Probeer het later opnieuw.',
				);
			})
			.finally(() => false);
	}
};

const handleRollback = (
	rollback,
	followup = false,
	alertUser = false,
	{ error, type } = null,
) => {
	if (alertUser) handleAlert(type, error);

	if (rollback && rollback.type) {
		handleDispatch(rollback.type, rollback.payload).finally(() => {
			if (followup) handleFollowup(rollback.followup);
		});
	}
};

const handleFollowup = followup => {
	if (followup && followup.length !== 0) {
		followup.forEach(func => {
			if (typeof func === 'function') {
				func(store.getState());
			}

			if (typeof func === 'object') {
				func.func(store.getState(), ...func.params);
			}
		});
	}
};

export const handleAlert = (type, text, timer = null) => {
	let options = { text, type };
	if (timer) options = { ...options, timer };

	store.dispatch({
		type: ACTION_TYPES.TOAST_ADD,
		payload: { toast: snackbarFactories(options) },
	});
};

const persistStore = () => {
	const state = store.getState();
	Object.keys(state).forEach(reducer => {
		localStorage.setItem(
			`reducer.${reducer}`,
			JSON.stringify(state[reducer]),
		);
	});
};
