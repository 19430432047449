import { ACTION_TYPES } from '../../../core/constants/action.constants/action.constants';

let defaultState = {
	departments: [],
	departmentsLoading: false,
	departmentsFetched: false,
};

const loadDepartments = state => ({
	...state,
	departmentsLoading: true,
});

const loadDepartmentsCommit = (state, { data }) => {
	return {
		...state,
		departments: [...state.departments, ...data],

		departmentsLoading: false,
		departmentsFetched: true,
	};
};

export const departmentsReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.DEPARTMENTS_LOAD:
			return loadDepartments(state, payload);
		case ACTION_TYPES.DEPARTMENTS_LOAD_COMMIT:
			return loadDepartmentsCommit(state, payload);
		default:
			return state;
	}
};
