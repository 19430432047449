import { useEffect, useMemo, useRef, useState } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chart as ChartJS } from 'chart.js/auto';

import CompanyActivity from '../../../components/CompanyActivity/CompanyActivity';
import PostItem from '../../../components/PostItem/PostItem';
import {
	getEmployeeData,
	getMovingMissionData,
	getSportData,
	getSportDataById,
} from '../../../services/MovingMission.service';
library.add(fas);
import './MovingMission.scss';

const MovingMission = () => {
	const chartRef = useRef(null);
	const [missionData, setMissionData] = useState({
		pointsRanking: [],
		streakRanking: [],
		contestants: [],
	});
	const [selectedDepartment, setSelectedDepartment] = useState('all');
	const [filteredEmployeeData, setFilteredEmployeeData] = useState(
		missionData.contestants,
	);
	const [selectedSport, setSelectedSport] = useState(null);
	const [sportData, setSportData] = useState(null);
	const [employeeProfile, setEmployeeProfile] = useState(null);
	const [hasSelectedEmployee, setHasSelectedEmployee] = useState(false);
	const [sportsIcons, setSportsIcons] = useState([]);

	const [employeeData, setEmployeeData] = useState([]);

	const sportColors = useMemo(
		() => ({
			baseball: '#FF6347',
			basketbal: '#FF4500',
			boksen: '#8C52FF',
			dans: '#FFD700',
			fietsen: '#ADFF2F',
			fitness: '#FF69B4',
			golf: '#4B0082',
			lopen: '#7CFC00',
			padel: '#DB7093',
			schaatsen: '#FFA07A',
			skiën: '#20B2AA',
			snowboarden: '#87CEFA',
			tennis: '#778899',
			voetbal: '#1ca31e',
			volleybal: '#FF00FF',
			wandelen: '#FF5757',
			yoga: '#800000',
			zwemmen: '#008080',
		}),
		[],
	);
	const handleDepartmentChange = event => {
		const selected = event.target.value;
		setSelectedDepartment(selected);

		if (selected === 'all') {
			setFilteredEmployeeData(missionData.contestants);
		} else {
			setFilteredEmployeeData(
				missionData.contestants.filter(
					employee =>
						employee.department.toLowerCase() ===
						selected.toLowerCase(),
				),
			);
		}
	};
	useEffect(() => {
		if (selectedSport) {
			getSportDataById(selectedSport).then(data => setSportData(data));
		} else {
			setSportData(null);
		}
	}, [selectedSport]);
	useEffect(() => {
		if (selectedDepartment === 'all') {
			setFilteredEmployeeData(missionData.contestants);
		} else {
			setFilteredEmployeeData(
				missionData.contestants.filter(
					employee =>
						employee.department.toLowerCase() ===
						selectedDepartment.toLowerCase(),
				),
			);
		}
	}, [selectedDepartment, missionData]);
	useEffect(() => {
		const fetchEmployeeData = async id => {
			const data = await getEmployeeData(id);
			setEmployeeProfile(data.user);
		};

		if (employeeProfile?.id) {
			fetchEmployeeData(employeeProfile.id);
		}
	}, [employeeProfile?.id]);

	useEffect(() => {
		const fetchSportsIcons = async () => {
			try {
				const sportsData = await getSportData();
				setSportsIcons(sportsData);
			} catch (error) {
				console.error('Error fetching sports data:', error);
			}
		};

		fetchSportsIcons();
	}, []);
	useEffect(() => {
		if (employeeData && employeeData.topThree && chartRef.current) {
			const labels = employeeData.topThree.map(item => item.type);
			const percentages = employeeData.topThree.map(
				item => item.percentage,
			);
			const backgroundColors = employeeData.topThree.map(
				item => sportColors[item.type.toLowerCase()],
			);

			if (window.myChart instanceof ChartJS) {
				window.myChart.destroy();
			}

			const ctx = chartRef.current.getContext('2d');
			window.myChart = new ChartJS(ctx, {
				type: 'doughnut',
				data: {
					labels: labels,
					datasets: [
						{
							data: percentages,
							backgroundColor: backgroundColors,
						},
					],
				},
				options: {
					plugins: {
						legend: { display: false },
						tooltip: { enabled: false },
					},
					animation: { animateScale: true, animateRotate: true },
					cutout: '50%',
				},
			});
		}
	}, [employeeData, sportColors]); // Depend on employeeData to trigger update

	useEffect(() => {
		const fetchMissionData = async () => {
			const data = await getMovingMissionData();
			setMissionData(data);
		};
		fetchMissionData();
	}, []);

	const handleEmployeeClick = async employeeId => {
		const profileData = await getEmployeeData(employeeId);
		if (profileData) {
			setEmployeeData(profileData); // Assuming you're updating the correct state with the fetched data
			setHasSelectedEmployee(true); // Update state to indicate an employee has been selected
		}
	};
	function capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	return (
		<div className='moving-mission-container'>
			<h1>Moving Mission</h1>
			<div className='content-container'>
				<div class='activities-wrapper'>
					<div className='standing-section'>
						<div className='lists-container'>
							<h3>Points Standing</h3>
							<ul>
								{(sportData
									? sportData.pointsRanking
									: missionData.pointsRanking
								).map((contestant, index) => (
									<li key={index}>
										<FontAwesomeIcon
											icon={['fas', 'medal']}
											style={{
												color:
													index === 0
														? 'gold'
														: index === 1
															? 'silver'
															: index === 2
																? '#cd7f32'
																: 'grey',
											}}
										/>{' '}
										{contestant.firstName}{' '}
										{contestant.lastName} -{' '}
										{contestant.points} points
									</li>
								))}
							</ul>
							{!sportData ? (
								<>
									<h3>Streak Standing</h3>
									<ul>
										{missionData.streakRanking.map(
											(contestant, index) => (
												<li key={index}>
													<FontAwesomeIcon
														icon={['fas', 'star']}
														style={{
															color:
																index === 0
																	? 'gold'
																	: index ===
																		  1
																		? 'silver'
																		: index ===
																			  2
																			? '#cd7f32'
																			: 'grey',
														}}
													/>{' '}
													{contestant.firstName}{' '}
													{contestant.lastName} -{' '}
													{contestant.streakDays} days
												</li>
											),
										)}
									</ul>
								</>
							) : null}
						</div>
						<div className='sports-section'>
							{sportsIcons
								? sportsIcons.map((icon, index) => {
										return (
											<div
												key={index}
												className={`sports-icon ${selectedSport === icon.id ? 'selected' : ''}`}
												onClick={() =>
													setSelectedSport(
														selectedSport ===
															icon.id
															? null
															: icon.id,
													)
												}
												style={
													selectedSport === icon.id
														? {
																border: '6px solid #08FF00',
															}
														: {}
												}>
												<FontAwesomeIcon
													icon={icon.icon}
												/>
												<span>{icon.name}</span>
											</div>
										);
									})
								: null}
						</div>
					</div>
					<CompanyActivity />
				</div>
				<div className='combined-section'>
					<div className='employee-section'>
						<select
							className='dropdown-menu'
							value={selectedDepartment}
							onChange={handleDepartmentChange}>
							<option value='all'>All</option>
							<option value='it'>IT</option>
							<option value='finance'>Finance</option>
							<option value='marketing'>Marketing</option>
						</select>
						<div className='employees-container'>
							{filteredEmployeeData.map(contestant => (
								<div
									key={contestant.id}
									className='employee-card'
									onClick={() =>
										handleEmployeeClick(contestant.id)
									}>
									<FontAwesomeIcon
										icon={['fas', 'user']}
										className='employee-icon'
									/>
									<div className='employee-info'>
										<div className='employee-name'>{`${contestant.firstName} ${contestant.lastName}`}</div>
										<div className='employee-title'>
											{contestant.department}
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
					{hasSelectedEmployee && employeeData ? (
						<div className='profile-section'>
							{employeeData ? (
								<>
									<div className='profile-info'>
										<FontAwesomeIcon
											icon={['fas', 'user']}
											className='employee-icon'
										/>
										<div className='name'>{`${employeeData.user.firstName} ${employeeData.user.lastName}`}</div>
										<div className='title'>
											{employeeData.user.department}
										</div>
									</div>
									<div className='data-chart-section'>
										<div className='data'>
											{employeeData?.topThree.map(
												(activity, index) => (
													<div
														className='data-item'
														key={index}>
														<div className='data-icon'>
															{sportsIcons
																? sportsIcons
																		.filter(
																			icon =>
																				icon.name.toLowerCase() ===
																				activity.type.toLowerCase(),
																		)
																		.map(
																			(
																				icon,
																				index,
																			) => (
																				<div
																					key={
																						index
																					}>
																					<FontAwesomeIcon
																						icon={
																							icon.icon
																						}
																						className={`data-icon ${icon.name.toLowerCase()}`}
																					/>
																				</div>
																			),
																		)
																: null}
														</div>
														<div>
															<span className='data-percentage'>{`${activity.percentage}%`}</span>
															<div
																className={`data-sport ${activity.type}`}>
																{capitalizeFirstLetter(
																	activity.type,
																)}
															</div>
														</div>
													</div>
												),
											)}
										</div>

										<div className='chart'>
											<canvas
												id='myChart'
												ref={chartRef}
											/>
											<div className='activities-info'>
												<div className='activities-count'>
													{
														employeeData.topThree
															.length
													}
												</div>
												<div className='activities-label'>
													Activities
												</div>
											</div>
										</div>
									</div>

									<div className='post-history'>
										<h3>Post History</h3>
										<div className='post-history-content'>
											{employeeData.history.map(
												(post, index) => {
													// Find the corresponding icon for the sport type
													const activityIcon =
														sportsIcons
															? sportsIcons.find(
																	icon =>
																		icon.name.toLowerCase() ===
																		post.type.toLowerCase(),
																).icon
															: null;

													return (
														<PostItem
															key={index}
															date={post.date}
															activity={post.type}
															duration={
																post.duration
															}
															activityIcon={
																activityIcon
															}
														/>
													);
												},
											)}
										</div>
									</div>
								</>
							) : null}
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default MovingMission;
