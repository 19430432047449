//todo when backend is implemented resolve no useles frangment
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import {
	faChevronLeft,
	faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AdminHeader } from '../../../../components/Layouts/AdminLayout/AdminHeader/AdminHeader';
import { ListItemModal } from '../../../../components/WideListItem/ListItemModal/ListItemModal';
import { WideListItem } from '../../../../components/WideListItem/WideListItem';
import { toShortDate } from '../../../../core/services/date';
import { loadEvents } from '../../../../core/store/events/events.actions';

const EventList = () => {
	const { events } = useSelector(state => state.eventReducer);
	const searchText = '';
	const [modal, setModal] = useState();

	let filteredItems = [];
	filteredItems = events.filter(event => {
		const ContainsSearchletter = event.name
			.toLowerCase()
			.includes(searchText.toLowerCase());
		return ContainsSearchletter;
	});

	//Poging tot pagineren
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(9);
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	let currentItems = filteredItems.slice(indexOfFirstPost, indexOfLastPost);

	const paginate = ({ selected }) => {
		setCurrentPage(selected + 1);
	};

	useEffect(() => {
		loadEvents();
		//loadTags("trainings");
	}, []);

	return (
		events && (
			<section className='admin-list'>
				<AdminHeader
					pageTitle='Alle evenementen'
					url='nieuw-evenement'
					type='evenement'
				/>

				{currentItems
					? currentItems.map((event, index) => (
							<WideListItem
								key={index}
								slug={event.slug}
								type='evenement'
								showImportantIcon={false}
								showImageIcon={true}
								showPinIcon={false}
								showIconToDelete={true}
								showIconToPublish={true}
								showIconToEdit={true}
								title={event.name}
								modal={modal}
								setModal={setModal}
								publishedAt={event.publishedAt}>
								<>
									<p>
										Published at :{' '}
										{toShortDate(event.publishedAt)}
									</p>
								</>
							</WideListItem>
						))
					: null}
				<ReactPaginate
					initialPage={0}
					onPageChange={paginate}
					pageCount={Math.ceil(currentItems.length / postsPerPage)}
					previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
					nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
					containerClassName='pagination'
					pageLinkClassName='page-number'
					previousLinkClassName='prev-page arrow-label'
					nextLinkClassName='next-page arrow-label'
					activeLinkClassName='active'
				/>

				<ListItemModal modal={modal} setModal={setModal} />
			</section>
		)
	);
};

export default EventList;
