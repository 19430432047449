export const Roles_example = [
	{
		id: 1,
		name: 'admin',
		created_at: '2021-01-07 10:01:09',
		updated_at: '2021-01-07 10:01:09',
		display_name: 'Admin',
	},
	{
		id: 2,
		name: 'dev',
		created_at: '2021-01-07 10:01:10',
		updated_at: '2021-01-07 10:01:10',
		display_name: 'Ontwikkelaar',
	},
	{
		id: 3,
		name: 'staff',
		created_at: '2021-01-07 10:01:12',
		updated_at: '2021-01-07 10:01:12',
		display_name: 'Staf',
	},
	{
		id: 4,
		name: 'coceco',
		created_at: '2021-01-07 10:01:13',
		updated_at: '2021-01-07 10:01:13',
		display_name: 'CoCeCo',
	},
	{
		id: 5,
		name: 'consultant',
		created_at: '2021-01-07 10:01:14',
		updated_at: '2021-01-07 10:01:14',
		display_name: 'Consultant',
	},
	{
		id: 6,
		name: 'instructor',
		created_at: '2021-01-07 10:01:14',
		updated_at: '2021-01-07 10:01:14',
		display_name: 'Lesgever',
	},
	{
		id: 7,
		name: 'editor',
		created_at: '2021-11-18 14:33:14',
		updated_at: '2021-11-18 14:33:14',
		display_name: 'Redacteur',
	},
];
