import React, { useEffect, useState } from 'react';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addDays, format, subDays } from 'date-fns';

import { getCompanyActivityData } from '../../services/MovingMission.service';

import './CompanyActivity.scss';

const CompanyActivity = () => {
	const [activityData, setActivityData] = useState(null);

	const [startDate, setStartDate] = useState(new Date('2024-03-01'));
	const [endDate, setEndDate] = useState(addDays(new Date('2024-03-01'), 18));

	useEffect(() => {
		const fetchData = async () => {
			const data = await getCompanyActivityData(
				format(startDate, 'yyyy-MM-dd'),
				format(endDate, 'yyyy-MM-dd'),
			);
			console.log('Fetched data:', data);
			console.log('topThree object:', data.topThree);
			setActivityData(data);
		};

		fetchData();
	}, [startDate, endDate]);

	const incrementDates = () => {
		setStartDate(prevDate => addDays(prevDate, 18));
		setEndDate(prevDate => addDays(prevDate, 18));
	};
	const decrementDates = () => {
		setStartDate(prevDate => subDays(prevDate, 18));
		setEndDate(prevDate => subDays(prevDate, 18));
	};
	// If activityData is not yet available, render a loading message
	if (!activityData) {
		return <div>Loading...</div>;
	}
	return (
		<div className='company-activity-section'>
			<div className='header-section'>
				<h3>Company Activity</h3>
				<div className='activity-legend'>
					{Object.values(activityData.topThree)
						.flat()
						.reduce((unique, activity) => {
							return unique.some(
								item =>
									item.type.toLowerCase() ===
									activity.type.toLowerCase(),
							)
								? unique
								: [...unique, activity];
						}, [])
						.map((activity, index) => {
							const capitalizedType =
								activity.type.charAt(0).toUpperCase() +
								activity.type.slice(1);
							return (
								<div className='legend-item' key={index}>
									<div
										className={`color-indicator ${activity.type.toLowerCase()}`}
									/>
									{capitalizedType}
								</div>
							);
						})}
				</div>
				<div className='date-handler'>
					<div className='date-arrow' onClick={decrementDates}>
						<FontAwesomeIcon icon={faArrowLeft} />
					</div>
					<div className='date-string'>
						{format(startDate, 'MMM d, yyyy')} -{' '}
						{format(endDate, 'MMM d, yyyy')}
					</div>
					<div className='date-arrow' onClick={incrementDates}>
						<FontAwesomeIcon icon={faArrowRight} />
					</div>
				</div>
			</div>
			<div className='activity-graph'>
				{Object.keys(activityData.topThree).map(day => (
					<div
						className='activity-bar'
						data-date={new Date(parseInt(day)).toDateString()}
						key={day}>
						{activityData.topThree[day].map((activity, index) => (
							<div
								className={`activity-segment ${activity.type.toLowerCase()}`}
								style={{
									height: `${activity.percentage}%`,
								}}
								key={index}
							/>
						))}
						<div className='day-number'>
							{new Date(parseInt(day)).getDate()}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default CompanyActivity;
