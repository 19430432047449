//todo when completed resolve no useles frangment
/* eslint-disable react/jsx-no-useless-fragment */

import {
	faBan,
	faCheck,
	faList,
	faUserGroup,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Selector.scss';

export const Selector = ({
	array,
	arraySelected,
	setArraySelected,
	handleRemoveItem,
	handleAddItem,
	searchText,
	setSearchText,
	setModal,
	SelectEveryOne,
	DeSelectEveryOne,
	AddSpecificGroupToSelected,
	DeselectSpecificGroupFromSelectedUsers,
	title,
	hideShortcuts,
	selectedGroups,
	setSelectedGroups,
	selectedRoles,
	AddSpecificRoleToSelected,
	DeselectRoleFromSelectedUsers,
}) => {
	const Departments = ['Finance', 'IT', 'RACS', 'UniPartners'];
	const CoCes = [
		'ACC',
		'AND',
		'APM',
		'CON',
		'DEV',
		'ENG',
		'FIN',
		'SYS',
		'COCECOs',
	];

	return (
		<section className='selector-container'>
			<section className='selector-container__select'>
				<label>{title}</label>
				{hideShortcuts == true ? null : (
					<section className='shortcut-container'>
						<div className='icon-container'>
							<FontAwesomeIcon
								icon={faList}
								className='icon list'
								onClick={() => setModal('overview-employees')}
							/>
							<FontAwesomeIcon
								icon={faUserGroup}
								className='icon everyone'
								onClick={() => {
									SelectEveryOne();
									setSelectedGroups(Departments);
								}}
							/>
							{arraySelected.length != 0 ? (
								<FontAwesomeIcon
									icon={faBan}
									className='icon'
									onClick={() => DeSelectEveryOne()}
								/>
							) : null}
						</div>
						{selectedGroups && setSelectedGroups ? (
							<div className='button-container'>
								{Departments.map((button, index) =>
									selectedGroups.includes(button) ? (
										<button
											className='btn btn--primary'
											key={index}
											onClick={() => {
												DeselectSpecificGroupFromSelectedUsers(
													button,
												);
											}}>
											{button}
										</button>
									) : (
										<button
											className='btn btn--secondary'
											key={index}
											onClick={() => {
												AddSpecificGroupToSelected(
													button,
												);
											}}>
											{button}
										</button>
									),
								)}
							</div>
						) : null}
						{selectedGroups && setSelectedGroups ? (
							<div className='button-container coces'>
								{CoCes.map((coce, index) =>
									selectedRoles.includes(coce) ? (
										<button
											className='btn btn--primary'
											key={index}
											onClick={() => {
												DeselectRoleFromSelectedUsers(
													coce,
												);
											}}>
											{' '}
											{coce}{' '}
										</button>
									) : (
										<button
											className='btn btn--secondary'
											key={index}
											onClick={() => {
												AddSpecificRoleToSelected(coce);
											}}>
											{' '}
											{coce}{' '}
										</button>
									),
								)}
							</div>
						) : null}
					</section>
				)}
				<div className='input-container'>
					<input
						className='searchbar-employees'
						type='text'
						placeholder='Zoek op voornaam...'
						onChange={event => {
							{
								setSearchText(event.target.value);
							}
						}}
					/>
				</div>
				<div className='dropdown '>
					{searchText
						? array
								.filter(item => {
									const ContainsSearchletter =
										`${item.firstName} ${item.lastName}`
											.toLowerCase()
											.includes(searchText.toLowerCase());
									return ContainsSearchletter;
								})
								.map((item, index) => (
									<>
										{arraySelected.find(
											werker => werker === item,
										) != undefined ? (
											<section
												key={index}
												className='item'
												onClick={() =>
													handleRemoveItem(item)
												}>
												<p className='geselecteerd'>
													{item.firstName}{' '}
													{item.lastName}
												</p>
												<FontAwesomeIcon
													icon={faCheck}
													className='icon checked'
												/>
											</section>
										) : (
											<section
												className='item'
												onClick={() =>
													handleAddItem(
														item,
														array,
														arraySelected,
														setArraySelected,
													)
												}>
												<p>
													{item.firstName}{' '}
													{item.lastName}
												</p>
											</section>
										)}
									</>
								))
						: null}
				</div>
			</section>

			<section className='selector-container__selected'>
				<label>
					Geselecteerde {title} <span>({arraySelected.length})</span>
				</label>
				{arraySelected.length != 0 ? (
					<section className='selected-container'>
						{arraySelected.map((medeWerker, index) => (
							<p
								key={index}
								className='selected'
								onClick={() => handleRemoveItem(medeWerker)}>
								{' '}
								<span>
									{medeWerker.firstName} {medeWerker.lastName}
								</span>
							</p>
						))}
					</section>
				) : null}
			</section>
		</section>
	);
};
