import { ACTION_TYPES } from '../../../core/constants/action.constants/action.constants';
import { dispatchAction } from '../../../core/services/actions';
import { store } from '../store.config';

export const loadEvents = () => {
	const state = store.getState();
	const { eventsLoading } = state.eventReducer;
	if (!eventsLoading) {
		dispatchAction({
			type: ACTION_TYPES.EVENT_FEEDS_LOAD,
			payload: { eventsLoading: true },
			meta: {
				action: {
					effect: {
						url: `/events`,
						method: 'get',
					},
					commit: { type: ACTION_TYPES.EVENT_FEEDS_LOAD_COMMIT },
					rollback: {
						type: ACTION_TYPES.EVENT_FEEDS_LOAD,
						payload: { eventsLoading: false },
					},
				},
			},
		});
	}
};
