import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
	faChalkboardTeacher,
	faChampagneGlasses,
	faChevronLeft,
	faChevronRight,
	faGraduationCap,
	faHouse,
	faNewspaper,
	faRankingStar,
	faTasks,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Sidebar.scss';

const Sidebar = ({ isOpen, toggleSidebar }) => {
	const { permissions } = useSelector(state => state.authReducer);

	return (
		<div className={`sidebar ${isOpen ? 'open' : ''}`}>
			<ul className='sidebar__nav'>
				<li className='sidebar__nav-item sidebar__nav-dropdown'>
					<NavLink to='/' className='sidebar__nav-link'>
						<span>Dashboard</span>
						<FontAwesomeIcon
							icon={faHouse}
							className='icon'
							fixedWidth
						/>
					</NavLink>
				</li>

				<li className='sidebar__nav-item sidebar__nav-dropdown'>
					<NavLink to='/opleidingen' className='sidebar__nav-link'>
						<span>Opleidingen</span>
						<FontAwesomeIcon
							icon={faGraduationCap}
							className='icon'
							fixedWidth
						/>
					</NavLink>
				</li>
				<li
					className={`sidebar__nav-item${
						permissions.includes('news_add')
							? ' sidebar__nav-dropdown'
							: ''
					}`}>
					<NavLink to='/nieuws' className='sidebar__nav-link'>
						<span>Nieuws</span>
						<FontAwesomeIcon
							icon={faNewspaper}
							className='icon'
							fixedWidth
						/>
					</NavLink>
				</li>
				<li className='sidebar__nav-item sidebar__nav-dropdown'>
					<NavLink to='/evenementen' className='sidebar__nav-link'>
						<span>Evenementen</span>
						<FontAwesomeIcon
							icon={faChampagneGlasses}
							className='icon'
							fixedWidth
						/>
					</NavLink>
				</li>

				<li className='sidebar__nav-item sidebar__nav-dropdown'>
					<NavLink to='/lesgevers' className='sidebar__nav-link'>
						<span>Lesgevers</span>
						<FontAwesomeIcon
							icon={faChalkboardTeacher}
							className='icon'
							fixedWidth
						/>
					</NavLink>
				</li>
				<li className='sidebar__nav-item sidebar__nav-dropdown'>
					<NavLink to='/permissies' className='sidebar__nav-link'>
						<span>Permissies</span>
						<FontAwesomeIcon
							icon={faTasks}
							className='icon'
							fixedWidth
						/>
					</NavLink>
				</li>
				<li className='sidebar__nav-item sidebar__nav-dropdown'>
					<NavLink to='/moving-mission' className='sidebar__nav-link'>
						<span>Moving Mission</span>
						<FontAwesomeIcon
							icon={faRankingStar}
							className='icon'
							fixedWidth
						/>
					</NavLink>
				</li>
			</ul>
			<button
				className={`sidebar-handle ${isOpen ? 'open' : ''}`}
				onClick={toggleSidebar}>
				<FontAwesomeIcon
					icon={isOpen ? faChevronLeft : faChevronRight}
				/>
			</button>
		</div>
	);
};

export default Sidebar;
