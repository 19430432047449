import { ACTION_TYPES } from '../../../core/constants/action.constants/action.constants';

let defaultState = {
	authLoading: false,
	loggedIn: false,
	token: null,
	authId: null,
	authUser: null,
	thumbnail: null,
	role: null,
	permissions: [],
	settings: null,
	contacts: [],
	meInfoFetched: false,
	loginTries: 0,
};

const loginUser = (state, { authLoading }) => ({
	...state,
	authLoading,
	loggedIn: true,
});

const loginUserCommit = (state, { token, authId }) => ({
	...state,
	token,
	authId,
	loggedIn: token !== null,
});

// eslint-disable-next-line no-unused-vars
const getNewToken = (state, { token }) => ({
	...state,
	token,
});

const logoutUser = state => ({
	authLoading: false,
	loggedIn: false,
	token: null,
	authId: null,
	authUser: null,
	thumbnail: null,
	role: null,
	permissions: [],
	settings: null,
	contacts: [],
	loginTries: state.loginTries,
});
const loadUserProfile = (state, { authLoading, loginTries }) => ({
	...state,
	authLoading,
	meInfoFetched: true,
	loginTries,
});

const loadUserProfileCommit = (
	state,
	{ role, permissions, settings, thumbnail, ...authUser },
) => ({
	...state,
	authUser,
	authLoading: false,
	thumbnail,
	role,
	permissions,
	settings,
});

const updateUserData = (state, data) => {
	if (
		Object.keys(data).every(key =>
			Object.prototype.hasOwnProperty.call(defaultState, key),
		)
	) {
		return { ...state, ...data };
	}
	return { ...state };
};

const loadContacts = (state, data) => ({
	...state,
	contacts: data.map(contact => ({
		...contact,
		picture: {
			type: 'user-picture',
			uuid: contact.picture,
		},
	})),
});

const updateBiography = (state, data) => ({
	...state,
	authUser: {
		...state.authUser,
		biography: data,
	},
});

const updateSocialMedia = (state, data) => ({
	...state,
	authUser: {
		...state.authUser,
		socialMedia: data,
	},
});

export const authReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.AUTH_LOGIN:
			return loginUser(state, payload);
		case ACTION_TYPES.AUTH_LOGIN_COMMIT:
			return loginUserCommit(state, payload.authorisation);
		case ACTION_TYPES.AUTH_LOGOUT:
			return state;
		case ACTION_TYPES.AUTH_LOGOUT_COMMIT:
			return logoutUser(state);
		case ACTION_TYPES.AUTH_LOAD_PROFILE:
			return loadUserProfile(state, payload);
		case ACTION_TYPES.AUTH_LOAD_PROFILE_COMMIT:
			return loadUserProfileCommit(state, payload.data);
		case ACTION_TYPES.AUTH_LOAD_PICTURE:
		case ACTION_TYPES.USER_UPDATE:
			return updateUserData(state, payload);
		case ACTION_TYPES.AUTH_LOAD_CONTACTS_COMMIT:
			return loadContacts(state, payload?.data);
		case ACTION_TYPES.UPDATE_BIOGRAPHY:
			return updateBiography(state, payload);
		case ACTION_TYPES.UPDATE_SOCIAL_MEDIA:
			return updateSocialMedia(state, payload);
		default:
			return state;
	}
};
