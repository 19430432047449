export const Roles_Permissions_example = [
	{
		id: 1,
		name: 'admin',
		roles: [
			{
				name: 'mainfeed_see',
				enabled: true,
			},
			{
				id: 2,
				name: 'subfeed_see',
				enabled: true,
			},
			{
				id: 3,
				name: 'users_see_overview',
				enabled: true,
			},
			{
				id: 4,
				name: 'users_see_detail',
				enabled: true,
			},
			{
				id: 5,
				name: 'users_see_full_detail',
				enabled: true,
			},
			{
				id: 6,
				name: 'users_reset_account',
				enabled: true,
			},
			{
				id: 7,
				name: 'users_see_notifications',
				enabled: true,
			},
			{
				id: 8,
				name: 'claims_illnesses_see',
				enabled: true,
			},
			{
				id: 9,
				name: 'claims_illnesses_add',
				enabled: true,
			},
			{
				id: 10,
				name: 'claims_damages_see',
				enabled: true,
			},
			{
				id: 11,
				name: 'claims_damages_add',
				enabled: true,
			},
			{
				id: 12,
				name: 'documents_see',
				enabled: true,
			},
			{
				id: 13,
				name: 'news_see_published',
				enabled: true,
			},
			{
				id: 14,
				name: 'news_see_unpublished',
				enabled: true,
			},
			{
				id: 15,
				name: 'news_add',
				enabled: true,
			},
			{
				id: 16,
				name: 'news_update',
				enabled: true,
			},
			{
				id: 17,
				name: 'news_publish',
				enabled: true,
			},
			{
				id: 18,
				name: 'news_delete',
				enabled: true,
			},
			{
				id: 19,
				name: 'news_pin',
				enabled: true,
			},
			{
				id: 20,
				enabled: true,
			},
			{
				id: 21,
				name: 'events_see_unpublished',
				enabled: true,
			},
			{
				id: 22,
				name: 'events_add',
				enabled: true,
			},
			{
				id: 23,
				name: 'events_update',
				enabled: true,
			},
			{
				id: 24,
				name: 'events_publish',
				enabled: true,
			},
			{
				id: 25,
				name: 'events_delete',
				enabled: true,
			},
			{
				id: 26,
				name: 'trainings_see_published',
				enabled: true,
			},
			{
				id: 27,
				name: 'trainings_see_unpublished',
				enabled: true,
			},
			{
				id: 28,
				name: 'trainings_add',
				enabled: true,
			},
			{
				id: 29,
				name: 'trainings_update',
				enabled: true,
			},
			{
				id: 30,
				name: 'trainings_publish',
				enabled: true,
			},
			{
				id: 31,
				name: 'trainings_delete',
				enabled: true,
			},
			{
				id: 32,
				name: 'comments_see',
				enabled: true,
			},
			{
				id: 33,
				name: 'comments_add',
				enabled: true,
			},
			{
				id: 34,
				name: 'comments_mention',
				enabled: true,
			},
			{
				id: 35,
				name: 'stickers_add',
				enabled: true,
			},
			{
				id: 36,
				name: 'claims_illnesses_admin',
				enabled: true,
			},
			{
				id: 37,
				name: 'claims_damages_admin',
				enabled: true,
			},
			{
				id: 38,
				name: 'sandwiches_admin',
				enabled: true,
			},
			{
				id: 39,
				name: 'permissions_admin',
				enabled: true,
			},
			{
				id: 40,
				name: 'users_see_timesheet',
				enabled: true,
			},
			{
				id: 41,
				name: 'events_see_results',
				enabled: true,
			},
			{
				id: 42,
				name: 'news_see_results',
				enabled: true,
			},
			{
				id: 43,
				name: 'trainings_see_results',
				enabled: true,
			},
			{
				id: 44,
				name: 'instructors_admin',
				enabled: true,
			},
			{
				id: 45,
				name: 'invitations_see',
				enabled: true,
			},
		],
	},
	{
		id: 2,
		name: 'dev',
		roles: [
			{
				name: 'mainfeed_see',
				enabled: true,
			},
			{
				id: 2,
				name: 'subfeed_see',
				enabled: true,
			},
			{
				id: 3,
				name: 'users_see_overview',
				enabled: true,
			},
			{
				id: 4,
				name: 'users_see_detail',
				enabled: true,
			},
			{
				id: 5,
				name: 'users_see_full_detail',
				enabled: true,
			},
			{
				id: 6,
				name: 'users_reset_account',
				enabled: true,
			},
			{
				id: 7,
				name: 'users_see_notifications',
				enabled: true,
			},
			{
				id: 8,
				name: 'claims_illnesses_see',
				enabled: true,
			},
			{
				id: 9,
				name: 'claims_illnesses_add',
				enabled: true,
			},
			{
				id: 10,
				name: 'claims_damages_see',
				enabled: true,
			},
			{
				id: 11,
				name: 'claims_damages_add',
				enabled: true,
			},
			{
				id: 12,
				name: 'documents_see',
				enabled: true,
			},
			{
				id: 13,
				name: 'news_see_published',
				enabled: true,
			},
			{
				id: 14,
				name: 'news_see_unpublished',
				enabled: true,
			},
			{
				id: 15,
				name: 'news_add',
				enabled: true,
			},
			{
				id: 16,
				name: 'news_update',
				enabled: true,
			},
			{
				id: 17,
				name: 'news_publish',
				enabled: true,
			},
			{
				id: 18,
				name: 'news_delete',
				enabled: true,
			},
			{
				id: 19,
				name: 'news_pin',
				enabled: true,
			},
			{
				id: 20,
				enabled: true,
			},
			{
				id: 21,
				name: 'events_see_unpublished',
				enabled: true,
			},
			{
				id: 22,
				name: 'events_add',
				enabled: true,
			},
			{
				id: 23,
				name: 'events_update',
				enabled: true,
			},
			{
				id: 24,
				name: 'events_publish',
				enabled: true,
			},
			{
				id: 25,
				name: 'events_delete',
				enabled: true,
			},
			{
				id: 26,
				name: 'trainings_see_published',
				enabled: true,
			},
			{
				id: 27,
				name: 'trainings_see_unpublished',
				enabled: true,
			},
			{
				id: 28,
				name: 'trainings_add',
				enabled: true,
			},
			{
				id: 29,
				name: 'trainings_update',
				enabled: true,
			},
			{
				id: 30,
				name: 'trainings_publish',
				enabled: true,
			},
			{
				id: 31,
				name: 'trainings_delete',
				enabled: true,
			},
			{
				id: 32,
				name: 'comments_see',
				enabled: true,
			},
			{
				id: 33,
				name: 'comments_add',
				enabled: true,
			},
			{
				id: 34,
				name: 'comments_mention',
				enabled: true,
			},
			{
				id: 35,
				name: 'stickers_add',
				enabled: true,
			},
			{
				id: 36,
				name: 'claims_illnesses_admin',
				enabled: true,
			},
			{
				id: 37,
				name: 'claims_damages_admin',
				enabled: true,
			},
			{
				id: 38,
				name: 'sandwiches_admin',
				enabled: true,
			},
			{
				id: 39,
				name: 'permissions_admin',
				enabled: true,
			},
			{
				id: 40,
				name: 'users_see_timesheet',
				enabled: true,
			},
			{
				id: 41,
				name: 'events_see_results',
				enabled: true,
			},
			{
				id: 42,
				name: 'news_see_results',
				enabled: true,
			},
			{
				id: 43,
				name: 'trainings_see_results',
				enabled: true,
			},
			{
				id: 44,
				name: 'instructors_admin',
				enabled: true,
			},
			{
				id: 45,
				name: 'invitations_see',
				enabled: true,
			},
		],
	},
	{
		id: 3,
		name: 'staff',
		roles: [
			{
				name: 'mainfeed_see',
				enabled: true,
			},
			{
				id: 2,
				name: 'subfeed_see',
				enabled: true,
			},
			{
				id: 3,
				name: 'users_see_overview',
				enabled: true,
			},
			{
				id: 4,
				name: 'users_see_detail',
				enabled: true,
			},
			{
				id: 5,
				name: 'users_see_full_detail',
				enabled: true,
			},
			{
				id: 6,
				name: 'users_reset_account',
				enabled: true,
			},
			{
				id: 7,
				name: 'users_see_notifications',
				enabled: true,
			},
			{
				id: 8,
				name: 'claims_illnesses_see',
				enabled: true,
			},
			{
				id: 9,
				name: 'claims_illnesses_add',
				enabled: true,
			},
			{
				id: 10,
				name: 'claims_damages_see',
				enabled: true,
			},
			{
				id: 11,
				name: 'claims_damages_add',
				enabled: true,
			},
			{
				id: 12,
				name: 'documents_see',
				enabled: true,
			},
			{
				id: 13,
				name: 'news_see_published',
				enabled: true,
			},
			{
				id: 14,
				name: 'news_see_unpublished',
				enabled: true,
			},
			{
				id: 15,
				name: 'news_add',
				enabled: true,
			},
			{
				id: 16,
				name: 'news_update',
				enabled: true,
			},
			{
				id: 17,
				name: 'news_publish',
				enabled: true,
			},
			{
				id: 18,
				name: 'news_delete',
				enabled: true,
			},
			{
				id: 19,
				name: 'news_pin',
				enabled: true,
			},
			{
				id: 20,
				enabled: true,
			},
			{
				id: 21,
				name: 'events_see_unpublished',
				enabled: true,
			},
			{
				id: 22,
				name: 'events_add',
				enabled: true,
			},
			{
				id: 23,
				name: 'events_update',
				enabled: true,
			},
			{
				id: 24,
				name: 'events_publish',
				enabled: true,
			},
			{
				id: 25,
				name: 'events_delete',
				enabled: true,
			},
			{
				id: 26,
				name: 'trainings_see_published',
				enabled: false,
			},
			{
				id: 27,
				name: 'trainings_see_unpublished',
				enabled: false,
			},
			{
				id: 28,
				name: 'trainings_add',
				enabled: false,
			},
			{
				id: 29,
				name: 'trainings_update',
				enabled: true,
			},
			{
				id: 30,
				name: 'trainings_publish',
				enabled: true,
			},
			{
				id: 31,
				name: 'trainings_delete',
				enabled: false,
			},
			{
				id: 32,
				name: 'comments_see',
				enabled: false,
			},
			{
				id: 33,
				name: 'comments_add',
				enabled: true,
			},
			{
				id: 34,
				name: 'comments_mention',
				enabled: true,
			},
			{
				id: 35,
				name: 'stickers_add',
				enabled: true,
			},
			{
				id: 36,
				name: 'claims_illnesses_admin',
				enabled: true,
			},
			{
				id: 37,
				name: 'claims_damages_admin',
				enabled: true,
			},
			{
				id: 38,
				name: 'sandwiches_admin',
				enabled: false,
			},
			{
				id: 39,
				name: 'permissions_admin',
				enabled: false,
			},
			{
				id: 40,
				name: 'users_see_timesheet',
				enabled: true,
			},
			{
				id: 41,
				name: 'events_see_results',
				enabled: true,
			},
			{
				id: 42,
				name: 'news_see_results',
				enabled: true,
			},
			{
				id: 43,
				name: 'trainings_see_results',
				enabled: true,
			},
			{
				id: 44,
				name: 'instructors_admin',
				enabled: false,
			},
			{
				id: 45,
				name: 'invitations_see',
				enabled: true,
			},
		],
	},
	{
		id: 4,
		name: 'coceco',
		roles: [
			{
				name: 'mainfeed_see',
				enabled: true,
			},
			{
				id: 2,
				name: 'subfeed_see',
				enabled: true,
			},
			{
				id: 3,
				name: 'users_see_overview',
				enabled: true,
			},
			{
				id: 4,
				name: 'users_see_detail',
				enabled: true,
			},
			{
				id: 5,
				name: 'users_see_full_detail',
				enabled: false,
			},
			{
				id: 6,
				name: 'users_reset_account',
				enabled: false,
			},
			{
				id: 7,
				name: 'users_see_notifications',
				enabled: true,
			},
			{
				id: 8,
				name: 'claims_illnesses_see',
				enabled: true,
			},
			{
				id: 9,
				name: 'claims_illnesses_add',
				enabled: true,
			},
			{
				id: 10,
				name: 'claims_damages_see',
				enabled: true,
			},
			{
				id: 11,
				name: 'claims_damages_add',
				enabled: true,
			},
			{
				id: 12,
				name: 'documents_see',
				enabled: true,
			},
			{
				id: 13,
				name: 'news_see_published',
				enabled: true,
			},
			{
				id: 14,
				name: 'news_see_unpublished',
				enabled: false,
			},
			{
				id: 15,
				name: 'news_add',
				enabled: false,
			},
			{
				id: 16,
				name: 'news_update',
				enabled: false,
			},
			{
				id: 17,
				name: 'news_publish',
				enabled: false,
			},
			{
				id: 18,
				name: 'news_delete',
				enabled: false,
			},
			{
				id: 19,
				name: 'news_pin',
				enabled: true,
			},
			{
				id: 20,
				enabled: true,
			},
			{
				id: 21,
				name: 'events_see_unpublished',
				enabled: true,
			},
			{
				id: 22,
				name: 'events_add',
				enabled: true,
			},
			{
				id: 23,
				name: 'events_update',
				enabled: true,
			},
			{
				id: 24,
				name: 'events_publish',
				enabled: true,
			},
			{
				id: 25,
				name: 'events_delete',
				enabled: true,
			},
			{
				id: 26,
				name: 'trainings_see_published',
				enabled: false,
			},
			{
				id: 27,
				name: 'trainings_see_unpublished',
				enabled: false,
			},
			{
				id: 28,
				name: 'trainings_add',
				enabled: false,
			},
			{
				id: 29,
				name: 'trainings_update',
				enabled: true,
			},
			{
				id: 30,
				name: 'trainings_publish',
				enabled: true,
			},
			{
				id: 31,
				name: 'trainings_delete',
				enabled: false,
			},
			{
				id: 32,
				name: 'comments_see',
				enabled: false,
			},
			{
				id: 33,
				name: 'comments_add',
				enabled: true,
			},
			{
				id: 34,
				name: 'comments_mention',
				enabled: true,
			},
			{
				id: 35,
				name: 'stickers_add',
				enabled: true,
			},
			{
				id: 36,
				name: 'claims_illnesses_admin',
				enabled: false,
			},
			{
				id: 37,
				name: 'claims_damages_admin',
				enabled: false,
			},
			{
				id: 38,
				name: 'sandwiches_admin',
				enabled: true,
			},
			{
				id: 39,
				name: 'permissions_admin',
				enabled: false,
			},
			{
				id: 40,
				name: 'users_see_timesheet',
				enabled: false,
			},
			{
				id: 41,
				name: 'events_see_results',
				enabled: true,
			},
			{
				id: 42,
				name: 'news_see_results',
				enabled: false,
			},
			{
				id: 43,
				name: 'trainings_see_results',
				enabled: true,
			},
			{
				id: 44,
				name: 'instructors_admin',
				enabled: false,
			},
			{
				id: 45,
				name: 'invitations_see',
				enabled: true,
			},
		],
	},
	{
		id: 5,
		name: 'consultant',
		roles: [
			{
				name: 'mainfeed_see',
				enabled: true,
			},
			{
				id: 2,
				name: 'subfeed_see',
				enabled: true,
			},
			{
				id: 3,
				name: 'users_see_overview',
				enabled: true,
			},
			{
				id: 4,
				name: 'users_see_detail',
				enabled: true,
			},
			{
				id: 5,
				name: 'users_see_full_detail',
				enabled: false,
			},
			{
				id: 6,
				name: 'users_reset_account',
				enabled: false,
			},
			{
				id: 7,
				name: 'users_see_notifications',
				enabled: true,
			},
			{
				id: 8,
				name: 'claims_illnesses_see',
				enabled: true,
			},
			{
				id: 9,
				name: 'claims_illnesses_add',
				enabled: true,
			},
			{
				id: 10,
				name: 'claims_damages_see',
				enabled: true,
			},
			{
				id: 11,
				name: 'claims_damages_add',
				enabled: true,
			},
			{
				id: 12,
				name: 'documents_see',
				enabled: true,
			},
			{
				id: 13,
				name: 'news_see_published',
				enabled: true,
			},
			{
				id: 14,
				name: 'news_see_unpublished',
				enabled: false,
			},
			{
				id: 15,
				name: 'news_add',
				enabled: false,
			},
			{
				id: 16,
				name: 'news_update',
				enabled: false,
			},
			{
				id: 17,
				name: 'news_publish',
				enabled: false,
			},
			{
				id: 18,
				name: 'news_delete',
				enabled: false,
			},
			{
				id: 19,
				name: 'news_pin',
				enabled: true,
			},
			{
				id: 20,
				enabled: true,
			},
			{
				id: 21,
				name: 'events_see_unpublished',
				enabled: false,
			},
			{
				id: 22,
				name: 'events_add',
				enabled: false,
			},
			{
				id: 23,
				name: 'events_update',
				enabled: false,
			},
			{
				id: 24,
				name: 'events_publish',
				enabled: false,
			},
			{
				id: 25,
				name: 'events_delete',
				enabled: false,
			},
			{
				id: 26,
				name: 'trainings_see_published',
				enabled: true,
			},
			{
				id: 27,
				name: 'trainings_see_unpublished',
				enabled: false,
			},
			{
				id: 28,
				name: 'trainings_add',
				enabled: false,
			},
			{
				id: 29,
				name: 'trainings_update',
				enabled: false,
			},
			{
				id: 30,
				name: 'trainings_publish',
				enabled: false,
			},
			{
				id: 31,
				name: 'trainings_delete',
				enabled: false,
			},
			{
				id: 32,
				name: 'comments_see',
				enabled: false,
			},
			{
				id: 33,
				name: 'comments_add',
				enabled: true,
			},
			{
				id: 34,
				name: 'comments_mention',
				enabled: true,
			},
			{
				id: 35,
				name: 'stickers_add',
				enabled: true,
			},
			{
				id: 36,
				name: 'claims_illnesses_admin',
				enabled: false,
			},
			{
				id: 37,
				name: 'claims_damages_admin',
				enabled: false,
			},
			{
				id: 38,
				name: 'sandwiches_admin',
				enabled: true,
			},
			{
				id: 39,
				name: 'permissions_admin',
				enabled: false,
			},
			{
				id: 40,
				name: 'users_see_timesheet',
				enabled: false,
			},
			{
				id: 41,
				name: 'events_see_results',
				enabled: true,
			},
			{
				id: 42,
				name: 'news_see_results',
				enabled: false,
			},
			{
				id: 43,
				name: 'trainings_see_results',
				enabled: true,
			},
			{
				id: 44,
				name: 'instructors_admin',
				enabled: false,
			},
			{
				id: 45,
				name: 'invitations_see',
				enabled: true,
			},
		],
	},
	{
		id: 6,
		name: 'instructor',
		roles: [
			{
				name: 'mainfeed_see',
				enabled: true,
			},
			{
				id: 2,
				name: 'subfeed_see',
				enabled: true,
			},
			{
				id: 3,
				name: 'users_see_overview',
				enabled: true,
			},
			{
				id: 4,
				name: 'users_see_detail',
				enabled: true,
			},
			{
				id: 5,
				name: 'users_see_full_detail',
				enabled: false,
			},
			{
				id: 6,
				name: 'users_reset_account',
				enabled: false,
			},
			{
				id: 7,
				name: 'users_see_notifications',
				enabled: true,
			},
			{
				id: 8,
				name: 'claims_illnesses_see',
				enabled: true,
			},
			{
				id: 9,
				name: 'claims_illnesses_add',
				enabled: true,
			},
			{
				id: 10,
				name: 'claims_damages_see',
				enabled: true,
			},
			{
				id: 11,
				name: 'claims_damages_add',
				enabled: true,
			},
			{
				id: 12,
				name: 'documents_see',
				enabled: true,
			},
			{
				id: 13,
				name: 'news_see_published',
				enabled: true,
			},
			{
				id: 14,
				name: 'news_see_unpublished',
				enabled: false,
			},
			{
				id: 15,
				name: 'news_add',
				enabled: false,
			},
			{
				id: 16,
				name: 'news_update',
				enabled: false,
			},
			{
				id: 17,
				name: 'news_publish',
				enabled: false,
			},
			{
				id: 18,
				name: 'news_delete',
				enabled: false,
			},
			{
				id: 19,
				name: 'news_pin',
				enabled: true,
			},
			{
				id: 20,
				enabled: true,
			},
			{
				id: 21,
				name: 'events_see_unpublished',
				enabled: false,
			},
			{
				id: 22,
				name: 'events_add',
				enabled: false,
			},
			{
				id: 23,
				name: 'events_update',
				enabled: false,
			},
			{
				id: 24,
				name: 'events_publish',
				enabled: false,
			},
			{
				id: 25,
				name: 'events_delete',
				enabled: false,
			},
			{
				id: 26,
				name: 'trainings_see_published',
				enabled: true,
			},
			{
				id: 27,
				name: 'trainings_see_unpublished',
				enabled: false,
			},
			{
				id: 28,
				name: 'trainings_add',
				enabled: false,
			},
			{
				id: 29,
				name: 'trainings_update',
				enabled: false,
			},
			{
				id: 30,
				name: 'trainings_publish',
				enabled: false,
			},
			{
				id: 31,
				name: 'trainings_delete',
				enabled: false,
			},
			{
				id: 32,
				name: 'comments_see',
				enabled: true,
			},
			{
				id: 33,
				name: 'comments_add',
				enabled: true,
			},
			{
				id: 34,
				name: 'comments_mention',
				enabled: false,
			},
			{
				id: 35,
				name: 'stickers_add',
				enabled: true,
			},
			{
				id: 36,
				name: 'claims_illnesses_admin',
				enabled: false,
			},
			{
				id: 37,
				name: 'claims_damages_admin',
				enabled: false,
			},
			{
				id: 38,
				name: 'sandwiches_admin',
				enabled: false,
			},
			{
				id: 39,
				name: 'permissions_admin',
				enabled: false,
			},
			{
				id: 40,
				name: 'users_see_timesheet',
				enabled: false,
			},
			{
				id: 41,
				name: 'events_see_results',
				enabled: false,
			},
			{
				id: 42,
				name: 'news_see_results',
				enabled: false,
			},
			{
				id: 43,
				name: 'trainings_see_results',
				enabled: true,
			},
			{
				id: 44,
				name: 'instructors_admin',
				enabled: false,
			},
			{
				id: 45,
				name: 'invitations_see',
				enabled: false,
			},
		],
	},
	{
		id: 7,
		name: 'editor',
		roles: [
			{
				name: 'mainfeed_see',
				enabled: true,
			},
			{
				id: 2,
				name: 'subfeed_see',
				enabled: true,
			},
			{
				id: 3,
				name: 'users_see_overview',
				enabled: true,
			},
			{
				id: 4,
				name: 'users_see_detail',
				enabled: true,
			},
			{
				id: 5,
				name: 'users_see_full_detail',
				enabled: true,
			},
			{
				id: 6,
				name: 'users_reset_account',
				enabled: false,
			},
			{
				id: 7,
				name: 'users_see_notifications',
				enabled: true,
			},
			{
				id: 8,
				name: 'claims_illnesses_see',
				enabled: false,
			},
			{
				id: 9,
				name: 'claims_illnesses_add',
				enabled: false,
			},
			{
				id: 10,
				name: 'claims_damages_see',
				enabled: true,
			},
			{
				id: 11,
				name: 'claims_damages_add',
				enabled: true,
			},
			{
				id: 12,
				name: 'documents_see',
				enabled: true,
			},
			{
				id: 13,
				name: 'news_see_published',
				enabled: true,
			},
			{
				id: 14,
				name: 'news_see_unpublished',
				enabled: true,
			},
			{
				id: 15,
				name: 'news_add',
				enabled: true,
			},
			{
				id: 16,
				name: 'news_update',
				enabled: true,
			},
			{
				id: 17,
				name: 'news_publish',
				enabled: true,
			},
			{
				id: 18,
				name: 'news_delete',
				enabled: true,
			},
			{
				id: 19,
				name: 'news_pin',
				enabled: true,
			},
			{
				id: 20,
				enabled: true,
			},
			{
				id: 21,
				name: 'events_see_unpublished',
				enabled: true,
			},
			{
				id: 22,
				name: 'events_add',
				enabled: true,
			},
			{
				id: 23,
				name: 'events_update',
				enabled: true,
			},
			{
				id: 24,
				name: 'events_publish',
				enabled: true,
			},
			{
				id: 25,
				name: 'events_delete',
				enabled: true,
			},
			{
				id: 26,
				name: 'trainings_see_published',
				enabled: true,
			},
			{
				id: 27,
				name: 'trainings_see_unpublished',
				enabled: true,
			},
			{
				id: 28,
				name: 'trainings_add',
				enabled: true,
			},
			{
				id: 29,
				name: 'trainings_update',
				enabled: true,
			},
			{
				id: 30,
				name: 'trainings_publish',
				enabled: true,
			},
			{
				id: 31,
				name: 'trainings_delete',
				enabled: true,
			},
			{
				id: 32,
				name: 'comments_see',
				enabled: true,
			},
			{
				id: 33,
				name: 'comments_add',
				enabled: true,
			},
			{
				id: 34,
				name: 'comments_mention',
				enabled: true,
			},
			{
				id: 35,
				name: 'stickers_add',
				enabled: true,
			},
			{
				id: 36,
				name: 'claims_illnesses_admin',
				enabled: false,
			},
			{
				id: 37,
				name: 'claims_damages_admin',
				enabled: false,
			},
			{
				id: 38,
				name: 'sandwiches_admin',
				enabled: true,
			},
			{
				id: 39,
				name: 'permissions_admin',
				enabled: false,
			},
			{
				id: 40,
				name: 'users_see_timesheet',
				enabled: false,
			},
			{
				id: 41,
				name: 'events_see_results',
				enabled: true,
			},
			{
				id: 42,
				name: 'news_see_results',
				enabled: true,
			},
			{
				id: 43,
				name: 'trainings_see_results',
				enabled: true,
			},
			{
				id: 44,
				name: 'instructors_admin',
				enabled: true,
			},
			{
				id: 45,
				name: 'invitations_see',
				enabled: true,
			},
		],
	},
];
