import { ACTION_TYPES } from '../../../core/constants/action.constants/action.constants';
import { dispatchAction } from '../../../core/services/actions';

export const loadAvailableStickers = () => {
	dispatchAction({
		type: ACTION_TYPES.STICKERS_INIT,
		payload: [],
		meta: {
			action: {
				effect: {
					url: `/stickers`,
					method: 'get',
				},
				commit: { type: ACTION_TYPES.STICKERS_INIT },
				rollback: {},
			},
		},
	});
};

export const loadStickers = (id, type) => {
	dispatchAction({
		type: ACTION_TYPES.STICKERS_LOAD,
		payload: { type, id },
		meta: {
			action: {
				effect: {
					url: `/${type}/${id}/stickers`,
					method: 'get',
				},
				commit: {
					type: ACTION_TYPES.STICKERS_LOAD_COMMIT,
					payload: { type, id },
				},
				rollback: {},
			},
		},
	});
};

export const postSticker = (stickerId, typeId, type) => {
	dispatchAction({
		meta: {
			action: {
				effect: {
					url: '/stickers',
					method: 'post',
					body: { stickerId, typeId, type },
				},
				rollback: {},
			},
		},
	});
};

export const stickerListener = (id, type, data) => {
	dispatchAction({
		type: ACTION_TYPES.STICKERS_LOAD_COMMIT,
		payload: { type, id, data },
	});
};
