import { NavLink } from 'react-router-dom';

import './AdminHeader.scss';

export const AdminHeader = ({ pageTitle, url, type }) => {
	return (
		<section className='admin-header'>
			<h1>{pageTitle}</h1>
			<section className='actions'>
				<NavLink to={url}>
					<button className='btn btn--primary'>Nieuw {type}</button>
				</NavLink>
			</section>
		</section>
	);
};
