import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import {
	faChevronLeft,
	faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { News_example } from '../../../../assets/JSON-examples/News/News_example';
import { AdminHeader } from '../../../../components/Layouts/AdminLayout/AdminHeader/AdminHeader';
import { ListItemModal } from '../../../../components/WideListItem/ListItemModal/ListItemModal';
import { WideListItem } from '../../../../components/WideListItem/WideListItem';

import './ArticleList.scss';

const ArticleList = () => {
	const FilterOptions = ['normal', 'important', 'pinned', 'unpublished'];
	const searchText = '';
	const [modal, setModal] = useState();
	const [currentItems, setCurrentItems] = useState([]);
	const [currentFilter, setCurrentFilter] = useState(FilterOptions[0]);
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(9);
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	let filteredItems = News_example.filter(nieuws => {
		const ContainsSearchletter = nieuws.title
			.toLowerCase()
			.includes(searchText.toLowerCase());
		return ContainsSearchletter;
	});

	const FilterForImportantItems = () => {
		let tempArray = filteredItems.filter(item => {
			if (item.urgent === 1) {
				return item;
			}
		});
		setCurrentItems(tempArray);
	};

	const paginate = ({ selected }) => {
		setCurrentPage(selected + 1);
	};

	const ToggleFilter = filterToShow => {
		if (filterToShow === currentFilter) {
			setCurrentFilter('normal');
			setCurrentItems(
				filteredItems.slice(indexOfFirstPost, indexOfLastPost),
			);
		} else if (filterToShow === 'important') {
			FilterForImportantItems();
			setCurrentFilter('important');
		} else if (filterToShow === 'pinned') {
			FilterForImportantItems();
			setCurrentFilter('pinned');
		} else if (filterToShow === 'unpublished') {
			FilterForImportantItems();
			setCurrentFilter('unpublished');
		} else {
			setCurrentFilter('normal');
		}
	};

	useEffect(() => {
		setCurrentItems(filteredItems.slice(indexOfFirstPost, indexOfLastPost));
	}, [filteredItems, indexOfFirstPost, indexOfLastPost]);
	return (
		News_example && (
			<section className=''>
				<AdminHeader
					pageTitle='Alle nieuws artikelen'
					type='nieuws artikel'
					url='nieuw-artikel'
				/>

				<section className='filter-container'>
					<button
						className={
							currentFilter === 'important'
								? `btn btn--primary`
								: ` btn btn--secondary`
						}
						onClick={() => {
							ToggleFilter('important');
						}}>
						Important
					</button>
					<button
						className={
							currentFilter === 'pinned'
								? `btn btn--primary`
								: ` btn btn--secondary`
						}
						onClick={() => {
							ToggleFilter('pinned');
						}}>
						Pinned
					</button>
					<button
						className={
							currentFilter === 'unpublished'
								? `btn btn--primary`
								: ` btn btn--secondary`
						}
						onClick={() => {
							ToggleFilter('unpublished');
						}}>
						Ongepubliceerd
					</button>
				</section>
				{currentItems
					? currentItems.map((nieuws, index) => (
							<WideListItem
								key={index}
								title={nieuws.title}
								showImageIcon={true}
								showPinIcon={true}
								showImportantIcon={true}
								showIconToDelete={true}
								showIconToPublish={true}
								showIconToEdit={true}
								publishedAt={nieuws.published_at}
								isImportant={nieuws.urgent}
								modal={modal}
								setModal={setModal}>
								<p>Published at : {nieuws.published_at}</p>
							</WideListItem>
						))
					: null}
				<ReactPaginate
					initialPage={0}
					onPageChange={paginate}
					pageCount={Math.ceil(filteredItems.length / postsPerPage)}
					previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
					nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
					containerClassName='pagination'
					pageLinkClassName='page-number'
					previousLinkClassName='prev-page arrow-label'
					nextLinkClassName='next-page arrow-label'
					activeLinkClassName='active'
				/>

				<ListItemModal modal={modal} setModal={setModal} />
			</section>
		)
	);
};

export default ArticleList;
