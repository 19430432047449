import { ACTION_TYPES } from '../../../core/constants/action.constants/action.constants';
import { getInviteStatus } from '../../../core/store/invites/invite.actions';

let defaultState = {
	trainings: [],
	trainingsLoading: false,
	training: null,
};

const loadTrainings = (state, { trainingsLoading }) => ({
	...state,
	trainingsLoading,
});

//Hier specifieren welk soort type data het is : bv hier trainings dus.
const loadTrainingsCommit = (state, { data }) => {
	//const hasNextPage = meta.current_page < meta.last_page;
	return {
		...state,
		trainings: data.map(training => ({ ...training, isLoaded: false })),
	};
};

const loadTrainingCommit = (state, { data }) => {
	getInviteStatus(data.id, 'trainings');
	return {
		...state,
		training: data,
	};
};

export const trainingReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.TRAINING_FEEDS_LOAD:
			return loadTrainings(state, payload);
		case ACTION_TYPES.TRAINING_FEEDS_LOAD_COMMIT:
			return loadTrainingsCommit(state, payload);
		case ACTION_TYPES.TRAINING_LOAD_COMMIT:
			return loadTrainingCommit(state, payload);
		default:
			return state;
	}
};
