import { Lesgever_example } from '../../../../assets/JSON-examples/Lesgevers/Lesgevers_example';
import { AdminHeader } from '../../../../components/Layouts/AdminLayout/AdminHeader/AdminHeader';
import { WideListItem } from '../../../../components/WideListItem/WideListItem';

export const TeacherList = () => {
	return (
		<section className='teacherView-container'>
			<AdminHeader
				pageTitle='Alle lesgevers'
				type='lesgever'
				url='nieuwe-lesgever'
			/>
			{Lesgever_example.map((lesgever, index) => (
				<WideListItem
					key={index}
					showIconToDelete={true}
					showIconToEdit={true}
					title={` ${lesgever.voornaam} ${lesgever.achternaam}`}>
					<p>{lesgever.mail}</p>
				</WideListItem>
			))}
		</section>
	);
};
