import {
	array,
	arrayOf,
	bool,
	func,
	number,
	oneOfType,
	shape,
	string,
} from 'prop-types';

import useFormInput from '../FormInput/useFormInput';

import './InputRadioButton.scss';

export const InputRadioButton = ({
	type,
	name,
	label,
	description,
	options,
	value,
	disabled,
	required,
	readOnly,
	validations,
	validate,
	errors,
	onChange,
	className,
}) => {
	const { touched, focused, id, ...handlers } = useFormInput({
		name,
		validate,
		errors,
		onChange,
		validations,
	});

	return (
		<div
			className={`radio-button${
				focused ? ' radio-button--focused' : ''
			}  ${className ? className : ''}`}>
			<div
				className={`radio-button__control${
					errors.length === 0 || !touched
						? ''
						: ' radio-button--invalid'
				}`}>
				<label className='radio-button__label' htmlFor={id}>
					{label} {required ? null : <code>(opt.)</code>}
				</label>
				<div
					className='radio-button__options'
					tabIndex='1'
					onBlur={handlers.onBlur}
					onFocus={handlers.onFocus}>
					{options.map(option => (
						<span
							className='radio-button__option'
							key={option.value}>
							<input
								type={type}
								id={option.value}
								name={id}
								value={option.value}
								onChange={handlers.onChange}
								readOnly={readOnly}
								disabled={option.disabled || disabled}
								checked={value == option.value}
							/>
							<label htmlFor={option.value}>{option.label}</label>
						</span>
					))}
				</div>
			</div>

			{errors.length > 0 && touched ? (
				<div className='radio-button__invalid-feedback'>
					{errors[0]}
				</div>
			) : (
				<div className='radio-button__description'>{description}</div>
			)}
		</div>
	);
};

InputRadioButton.defaultProps = {
	type: 'radio',
	label: '',
	description: '',
	required: false,
	readOnly: false,
	disabled: false,
	validations: [],
	errors: [],
};
InputRadioButton.propTypes = {
	type: string.isRequired,
	name: string.isRequired,
	label: string,
	description: string,
	defaultChecked: string,
	value: string,
	required: bool,
	readOnly: bool,
	options: arrayOf(
		shape({
			value: oneOfType([string, number]),
			label: string,
			disabled: bool,
		}),
	).isRequired,
	disabled: bool,
	validations: array,
	errors: array,
	onChange: func.isRequired,
	validate: func.isRequired,
	className: string,
};
export default InputRadioButton;
