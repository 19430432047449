import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import {
	faChevronLeft,
	faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AdminHeader } from '../../../../components/Layouts/AdminLayout/AdminHeader/AdminHeader';
import { ListItemModal } from '../../../../components/WideListItem/ListItemModal/ListItemModal';
import { WideListItem } from '../../../../components/WideListItem/WideListItem';
import { toShortDate } from '../../../../core/services/date';
import { loadTrainings } from '../../../../core/store/trainings/trainings.actions';

import './TrainingList.scss';

const TrainingList = () => {
	const { trainings } = useSelector(state => state.trainingReducer);
	//const [searchText, setSearchText] = useState("");
	const [modal, setModal] = useState();

	let filteredItems = [];
	filteredItems = trainings.filter(training => {
		const ContainsSearchletter = training.name.toLowerCase().includes();
		return ContainsSearchletter;
	});

	//Poging tot pagineren
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState(9);
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;
	let currentItems = filteredItems.slice(indexOfFirstPost, indexOfLastPost);

	const paginate = ({ selected }) => {
		setCurrentPage(selected + 1);
	};

	useEffect(() => {
		loadTrainings();
		//loadTags("trainings");
	}, []);

	return (
		trainings && (
			<section className='admin-list'>
				<AdminHeader
					pageTitle='Alle opleidingen'
					type='opleiding'
					url='nieuwe-opleiding'
				/>
				{currentItems
					? currentItems.map((training, index) => (
							<WideListItem
								key={index}
								type='opleiding'
								slug={training.slug}
								showImportantIcon={false}
								showImageIcon={true}
								showPinIcon={false}
								showIconToDelete={true}
								showIconToPublish={true}
								showIconToEdit={true}
								title={training.name}
								modal={modal}
								setModal={setModal}
								publishedAt={training.publishedAt}>
								<p>
									Published at :{' '}
									{toShortDate(training.publishedAt)}
								</p>
							</WideListItem>
						))
					: null}
				<ReactPaginate
					initialPage={0}
					onPageChange={paginate}
					pageCount={Math.ceil(filteredItems.length / postsPerPage)}
					previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
					nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
					containerClassName='pagination'
					pageLinkClassName='page-number'
					previousLinkClassName='prev-page arrow-label'
					nextLinkClassName='next-page arrow-label'
					activeLinkClassName='active'
				/>
				<ListItemModal modal={modal} setModal={setModal} />
			</section>
		)
	);
};

export default TrainingList;
