import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import moment from 'moment';

export const toShortDate = (inputDate, limit = false) => {
	let options = {};
	const timestamp = new Date(inputDate).getTime() / 1000; // Convert inputDate to a valid timestamp
	if (limit) options = { month: '2-digit', day: 'numeric' };
	else options = { year: 'numeric', month: '2-digit', day: 'numeric' };
	return new Date(timestamp * 1000).toLocaleDateString(`nl-NL`, options);
};

export const toTime = timestamp => {
	return moment.utc(timestamp, [moment.ISO_8601, 'HH:mm']).format('H:mm');
};

export const localDate = dateString => {
	const date = new Date(dateString);
	return format(date, 'd MMMM yyyy', { locale: nl });
};

/**
 * Checks is day is after yesterday
 * @param {{date: string}} day
 * @returns boolean
 */
export const isCurrent = day =>
	moment(day.date).isAfter(moment().subtract(1, 'd'));

/**
 * Finds the last day in an array of days
 * @param {{date: string}[]} days
 * @returns {date: string}
 */
export const lastDate = days =>
	days.reduce((pre, cur) =>
		Date.parse(pre.date) < Date.parse(cur.date) ? cur : pre,
	);

export const GetNumberOfCurrentWeek = () => {
	const today = new Date();
	let year = new Date(today.getFullYear(), 0, 1);
	var days = Math.floor((today - year) / (24 * 60 * 60 * 1000));
	var week = Math.ceil((today.getDay() + 1 + days) / 7);
	return week;
};

export const shortNameDays = [
	'maandag',
	'dinsdag',
	'woensdag',
	'donderdag',
	'vrijdag',
	'zaterdag',
	'zondag',
];

const DateStringOptions_withYear = {
	year: 'numeric',
	month: 'numeric',
	day: 'numeric',
};

export const getYearAndMonth = (date = new Date()) => {
	return {
		year: date.getFullYear(),
		month: date.getMonth() + 1,
	};
};

/**
 * Creates an array of arrays per week with the days of the month
 * starting with offset days from the previous month and ending with offset days from the next month
 * @param {{year: number, month: number}}
 * @returns {{string[]}[]} calendar
 */
export const getMonthDays = ({ year, month }) => {
	const firstDay = new Date(year, month - 1, 1);
	const lastDay = new Date(year, month, 0);
	const daysInMonth = lastDay.getDate();
	const firstDayOfWeek = firstDay.getDay(); // 0 for Sunday, 1 for Monday, ...

	// Calculate the day index for Monday as 0
	const mondayFirstDay = (firstDayOfWeek + 6) % 7;

	const calendar = [];
	let week = [];
	let day = 1;

	const addZero = number => number.toString().padStart(2, '0');

	// Add null values for days of the previous month
	for (let i = 0; i < mondayFirstDay; i++) {
		week.push(null);
	}

	// Add days of the current month
	while (day <= daysInMonth) {
		week.push(`${year}-${addZero(month)}-${addZero(day)}`);
		day++;

		if (week.length === 7) {
			calendar.push(week);
			week = [];
		}
	}

	// Add null values for days of the next month
	while (week.length < 7) {
		week.push(null);
	}

	calendar.push(week);

	return calendar;
};

export const getCurrentWeek = dateString => {
	const now = dateString ? new Date(dateString) : new Date();
	const startOfYear = new Date(now.getFullYear(), 0, 1);
	const diff = now - startOfYear;
	const oneWeekInMilliseconds = 1000 * 60 * 60 * 24 * 7;
	const weekNumber = Math.floor(diff / oneWeekInMilliseconds) + 1;
	return weekNumber;
};

/**
 * Calculates the date of the same day in the previous week
 * @param {string} dateString
 * @returns {{year: number, month: number, day: number, localString: string}}}
 */
export const oneWeekBefore = dateString => {
	return getDateWithOffset(dateString, -7);
};

/**
 * Calculates the date of the same day in the next week
 * @param {string} dateString
 * @returns {{year: number, month: number, day: number, localString: string}}
 */
export const oneWeekAfter = dateString => {
	return getDateWithOffset(dateString, 7);
};

const getDateWithOffset = (dateString, offset) => {
	const date = new Date(dateString);
	const weekBefore = new Date(date.setDate(date.getDate() + offset));
	return {
		year: weekBefore.getFullYear(),
		month: weekBefore.getMonth() + 1,
		day: weekBefore.getDate(),
		localString: weekBefore.toLocaleDateString(
			'nl-NL',
			DateStringOptions_withYear,
		),
	};
};

export const getWeekDaysByYearAndWeekNumber = (year, weekNum) => {
	let date = new Date(year, 0, 1 + (weekNum - 1) * 7); // January 1st + 7 days for each week
	let days = [];
	// Get the dates for each weekday starting from Monday to Sunday
	for (let i = 1; i <= 7; i++) {
		date.setDate(date.getDate() + ((i - date.getDay()) % 7)); // Calculate the date for the current weekday
		const day = {
			year: date.getFullYear(),
			month: date.getMonth() + 1,
			day: date.getDate(),
			localString: date.toLocaleDateString(
				'nl-NL',
				DateStringOptions_withYear,
			),
		};
		days.push(day); // Add the date to the array
	}
	return days;
};
