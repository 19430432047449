import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import AddNewItemPage from '../../../../components/Layouts/AdminLayout/AddNewItemPage/AddNewItemPage';
import { changeArrayPropertyNames } from '../../../../core/services/parse';
import { loadTags } from '../../../../core/store/tags/tag.actions';

import './TrainingAdd.scss';

const TrainingAdd = () => {
	const [title, setTitle] = useState('');
	const [tekst, setTekst] = useState();
	const [organisatoren, setOrganisatoren] = useState([]);
	const [geselecteerdeMedewerkers, setGeselecteerdeMedewerkers] = useState(
		[],
	);
	const [documents, setDocuments] = useState([]);
	const [volledigeDatum, setVolledigetDatum] = useState();
	const [locatie, setLocatie] = useState();
	const [limietOpInschrijvingen, setLimietOpInschrijvingen] = useState(10);
	const [links, setLinks] = useState([]);
	const { tags } = useSelector(state => state.tagReducer);

	useEffect(() => {
		loadTags('trainings');
	}, []);
	return (
		<AddNewItemPage
			type='opleiding'
			tags={changeArrayPropertyNames(tags.opleidingen?.data)}
			pageTitle='Opleiding toevoegen'
			title={title}
			setTitle={setTitle}
			organisatoren={organisatoren}
			setOrganisatoren={setOrganisatoren}
			geselecteerdeMedewerkers={geselecteerdeMedewerkers}
			setGeselecteerdeMedewerkers={setGeselecteerdeMedewerkers}
			volledigeDatum={volledigeDatum}
			setVolledigetDatum={setVolledigetDatum}
			locatie={locatie}
			setLocatie={setLocatie}
			uploadedDocuments={documents}
			uploadNewDocuments={setDocuments}
			limietOpInschrijvingen={limietOpInschrijvingen}
			setLimietOpInschrijvingen={setLimietOpInschrijvingen}
			optieVoorOpleidingsUren={true}
			optieVoorBroodjes={true}
			tekst={tekst}
			setTekst={setTekst}
			links={links}
			setLinks={setLinks}
		/>
	);
};

export default TrainingAdd;
