import Select from 'react-select';
import {
	array,
	bool,
	func,
	number,
	object,
	oneOfType,
	string,
} from 'prop-types';

import UiInput from '../UiInput/UiInput';
import useFormInput from '../useFormInput';

import './Select.scss';

const SelectField = ({
	label,
	name,
	value,
	validations,
	validate,
	errors,
	onChange,
	options,
	placeholder,
	description,
	required,
	disabled,
	className,
	isMulti,
	isClearable,
	returnOptionsObj,
}) => {
	const { touched, focused, ...handlers } = useFormInput({
		name,
		validate,
		errors,
		onChange,
		validations,
	});

	return (
		<UiInput
			hasValue={value ? value.length > 0 : null}
			errorMsgs={touched ? errors : []}
			label={label}
			className={className}
			disabled={disabled}
			required={required}
			focused={focused}
			description={description}>
			<Select
				className='select'
				name={name}
				classNamePrefix='select'
				options={options}
				isMulti={isMulti}
				closeMenuOnSelect={!isMulti}
				isClearable={isClearable ? !!value : false}
				value={
					!returnOptionsObj
						? (isMulti
								? options.filter(option =>
										value.includes(option.value),
									)
								: options.find(
										option => option.value === value,
									)) || null
						: value
				}
				{...handlers}
				onChange={(option, actionMeta) => {
					if (actionMeta.action === 'clear') {
						onChange('');
						return;
					}

					if (isMulti) {
						onChange(
							option.map(option =>
								!returnOptionsObj ? option.value : option,
							),
						);
					} else {
						onChange(!returnOptionsObj ? option.value : option);
					}
				}}
				placeholder={placeholder}
				isDisabled={disabled}
			/>
		</UiInput>
	);
};

SelectField.defaultProps = {
	label: '',
	placeholder: '',
	description: '',
	required: false,
	disabled: false,
	validations: [],
	errors: [],
	options: [],
	isMulti: false,
	isClearable: true,
	returnOptionsObj: false,
	value: '',
};

SelectField.propTypes = {
	name: string.isRequired,
	label: string,
	placeholder: string,
	description: string,
	value: oneOfType([string, number, array, object]),
	required: bool,
	disabled: bool,
	validations: array,
	errors: array,
	onChange: func.isRequired,
	validate: func.isRequired,
	options: array,
	isMulti: bool,
	isClearable: bool,
	returnOptionsObj: bool,
};

export default SelectField;
