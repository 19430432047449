/**
 * @param {object} fields
 * @returns {boolean}
 * @constructor
 */
export const FormValidation = fields => {
	return (
		Object.keys(fields)
			.map(key => fields[key].errors.length > 0)
			.indexOf(true) === -1
	);
};

export const isRequired = (val, label) => {
	const checkValue =
		typeof val === 'number' || !isNaN(val) || typeof val === 'boolean'
			? val
			: typeof val === 'string'
				? val?.trim().length
				: val?.length;
	if (checkValue < 0) {
		return `${label} moet een positief getal zijn.`;
	}
	return checkValue ? '' : `${label} is een verplicht veld.`;
};
export const isNumberWith2Decimals = (val, label) => {
	return val === '' || val.match(/^\d+(\.\d{1,2})?$/)
		? ''
		: `${label} moet een getal zijn met maximaal 2 decimalen.`;
};

const isValidEmail = email =>
	String(email)
		.toLowerCase()
		.match(
			/^((([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))?$/,
		);

export const isEmail = (val, label) => {
	const valid = isValidEmail(val);
	return valid ? '' : `${label} is ongeldig.`;
};

export const isEmailList = (val, label) => {
	const emails = val.replace(/\s/g, '').split(',');
	const invalidEmails = emails.filter(email => !isValidEmail(email));
	return invalidEmails.length === 0
		? ''
		: `${label} bevat ongeldige e-mailadressen.`;
};

export const isStrongPassword = (val, label) => {
	const passwordPattern = new RegExp(
		/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
	);

	return val === '' || !!passwordPattern.test(val)
		? ''
		: `${label} voldoet niet aan de vereisten van een wachtwoord.`;
};

export const isSmallerThan = max => (val, label) => {
	return +val <= max ? '' : `${label} mag niet groter zijn dan ${max}.`;
};

export const isValidUrl = (val, label) => {
	const urlPattern = new RegExp(
		'^(https?:\\/\\/)?' + // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
			'(\\#[-a-z\\d_]*)?$', // fragment locator
		'i',
	);
	return val === '' || !!urlPattern.test(val)
		? ''
		: `${label} voldoet niet aan de vereisten van een url.`;
};
