import React, { createContext, useContext, useEffect, useState } from 'react';
import { faWifi } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { node } from 'prop-types';

import { dispatchAction, dispatchMedia } from '../../core/services/actions';
import { db } from '../../dexie-db';

const OnlineStatusContext = createContext(true);

export const OnlineStatusProvider = ({ children }) => {
	const [onlineStatus, setOnlineStatus] = useState(navigator.onLine);

	useEffect(() => {
		window.addEventListener('offline', () => {
			setOnlineStatus(false);
		});

		window.addEventListener('online', () => {
			setOnlineStatus(true);

			db.dispatchActions
				.toArray()
				.forEach(action => dispatchAction({ ...action }));

			db.dispatchMedia
				.toArray()
				.forEach(action => dispatchMedia({ ...action }));
		});

		return () => {
			window.removeEventListener('offline', () => {
				setOnlineStatus(false);
			});

			window.removeEventListener('online', () => {
				setOnlineStatus(true);
			});
		};
	}, []);

	return (
		<OnlineStatusContext.Provider value={onlineStatus}>
			{!onlineStatus ? (
				<div className='offline'>
					<FontAwesomeIcon icon={faWifi} beatFade /> Je bent niet
					verbonden met het netwerk!
				</div>
			) : null}
			{children}
		</OnlineStatusContext.Provider>
	);
};

OnlineStatusProvider.propTypes = {
	children: node,
};

export const useOnlineStatus = () => {
	return useContext(OnlineStatusContext);
};
