import { faFile, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './FileUploader.scss';

export const FileUploader = ({
	uploadedDocuments,
	uploadNewDocuments,
	extraClass,
	showDocumentsInContainer,
	required,
}) => {
	const handleFileUpload = () => {
		let FileArray = [];
		const files = document.getElementById('fileInput').files;
		for (let i = 0; i < files.length; i++) {
			FileArray.push(files[i]);
		}
		uploadNewDocuments([...uploadedDocuments, ...FileArray]);
	};
	return (
		<section className={`file-uploader ${extraClass ? extraClass : ``}`}>
			<p className='upload-title'>
				Laad hier je bestanden in.{' '}
				{required === true ? <span>&#42;</span> : null}
			</p>

			<section className='upload-container'>
				<label className='custom-file-upload'>
					<input
						type='file'
						id='fileInput'
						multiple
						onChange={handleFileUpload}
					/>
					{uploadedDocuments &&
					showDocumentsInContainer != false &&
					uploadedDocuments.length != 0 ? (
						<section className='files-container'>
							{uploadedDocuments.map((docu, index) => (
								<section className='file-item' key={index}>
									<FontAwesomeIcon
										icon={faFile}
										className='file-icon'
									/>
									<p>{docu.name}</p>
								</section>
							))}
						</section>
					) : (
						<section className='file-item empty'>
							<FontAwesomeIcon
								icon={faUpload}
								className='file-icon'
							/>
							<p className='empty'>Laad hier je bestanden in.</p>
						</section>
					)}
				</label>
			</section>
		</section>
	);
};
