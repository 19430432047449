import { useEffect, useState } from 'react';

import { executeAxios } from '../../../core/services/actions';

const useDynamicBackgroundImage = imageUrl => {
	const [imageSrc, setImageSrc] = useState('');

	useEffect(() => {
		const [imageId, imageType] = (imageUrl + '' || '')
			.split('/')
			.slice(-1)[0]
			.split('_');
		const data = {
			type: imageType || 'original',
			images: [imageId],
		};

		const fetchImage = async () => {
			try {
				const {
					data: {
						data: [image],
					},
				} = await executeAxios({
					method: 'POST',
					url: '/data',
					data,
					headers: {
						'Content-Type': 'application/json',
					},
				});
				setImageSrc(image || '');
			} catch (error) {
				console.log(error);
			}
		};
		fetchImage();
	}, [imageUrl]);

	return imageSrc;
};

export default useDynamicBackgroundImage;
