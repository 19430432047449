import { ACTION_TYPES } from '../../../core/constants/action.constants/action.constants';

let defaultState = {
	toppings: [],
};

const initToppings = (state, { data }) => ({
	...state,
	toppings: data,
});

export const toppingReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.TOPPINGS_INIT:
			return initToppings(state, payload);
		default:
			return state;
	}
};
