import { memo } from 'react';
import {
	faChampagneGlasses,
	faGraduationCap,
	faNewspaper,
	faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { bool, string } from 'prop-types';

import useDynamicBackgroundImage from '../../core/hooks/api/useDynamicBackgroundImage';

import './Image.scss';

const Image = memo(
	({
		id,
		alt,
		shadow,
		rounded,
		className,
		onPress,
		objType,
		showPlaceholder,
	}) => {
		const image = useDynamicBackgroundImage(id);
		//TODO: add correct FontAwesomeIcon based on error

		let defaultIcon;

		switch (objType) {
			case 'news':
				defaultIcon = faNewspaper;
				break;
			case 'events':
				defaultIcon = faChampagneGlasses;
				break;
			case 'trainings':
				defaultIcon = faGraduationCap;
				break;
			default:
				defaultIcon = faUser;
				break;
		}

		return (
			<div className='image-container'>
				{image ? (
					<img
						src={`data:image/eng;base64,${image}`}
						alt={alt}
						onClick={onPress}
						className={`picture${className ? ` ${className}` : ''}${
							rounded ? ' rounded' : ''
						}${shadow ? ' with-shadow' : ''}`}
						style={{ display: image && 'block' }}
					/>
				) : showPlaceholder ? (
					<div
						onClick={onPress}
						className={`picture${className ? ` ${className}` : ''}${
							rounded ? ' rounded' : ''
						}${shadow ? ' with-shadow' : ''}`}>
						<FontAwesomeIcon icon={defaultIcon} fixedWidth />
					</div>
				) : null}
			</div>
		);
	},
);

Image.defaultProps = {
	shadow: false,
	rounded: false,
	className: '',
	objType: '',
	showPlaceholder: true,
};

Image.propTypes = {
	id: string,
	alt: string,
	shadow: bool,
	rounded: bool,
	className: string,
	objType: string,
	showPlaceholder: bool,
};

export default Image;
