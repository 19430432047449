import { ACTION_TYPES } from '../../../core/constants/action.constants/action.constants';

let defaultState = {
	events: [],
	eventsLoading: false,
};

const loadEvents = (state, { eventsLoading }) => ({
	...state,
	eventsLoading,
});

//Hier specifieren welk soort type data het is : bv hier events dus.
const loadEventsCommit = (state, { data }) => {
	//const hasNextPage = meta.current_page < meta.last_page;
	return {
		...state,
		events: data.map(event => ({ ...event, isLoaded: false })),
	};
};

export const eventReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.EVENT_FEEDS_LOAD:
			return loadEvents(state, payload);
		case ACTION_TYPES.EVENT_FEEDS_LOAD_COMMIT:
			return loadEventsCommit(state, payload);
		default:
			return state;
	}
};
