import IcoMoon from 'react-icomoon';
import { string } from 'prop-types';

import iconSet from '../../assets/icons/selection.json';

const Icon = props => <IcoMoon iconSet={iconSet} {...props} />;

Icon.propTypes = {
	icon: string.isRequired,
};

export default Icon;
