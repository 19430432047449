import { useRef, useState } from 'react';
import {
	faFile,
	faPaperclip,
	faTimes,
	faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, bool, func, number, object, string } from 'prop-types';

import { getFileSize } from '../../../../core/services/file';
import UiInput from '../UiInput/UiInput';

import './Upload.scss';

const Upload = ({
	accept,
	className,
	description,
	disabled,
	label,
	multiple,
	name,
	required,
	type,
	maxSize,
	value: currentFiles,
	onChange: setCurrentFiles,
	onDelete,
}) => {
	const ref = useRef(null);
	const [error, setError] = useState(null);

	const id = name
		.toLowerCase()
		.split(' ')
		.map(word => word.replace(/[^a-z]+/g, ''))
		.join('-');

	const handleAdd = e => {
		const { files } = e.target;
		if (files[0].size > maxSize) {
			setError(
				`Bestand is te groot. Kies een bestand kleiner dan ${
					maxSize / 1000000
				}MB.`,
			);
			e.target.value = null;
			return;
		}
		const updatedFilesArray = [...currentFiles, ...files];
		setCurrentFiles(updatedFilesArray);
	};

	const handleRemove = async (e, removedFile) => {
		try {
			e.preventDefault();
			if (onDelete && !removedFile.size) {
				await onDelete(removedFile);
			}

			const findIndex = currentFiles.findIndex(
				file => file.name === removedFile.name,
			);

			const filteredFilesArray = currentFiles.filter(
				(_, index) => index !== findIndex,
			);

			const list = new DataTransfer();

			filteredFilesArray.forEach(
				file => typeof file === File && list.items.add(file),
			);
			ref.current.files = list.files;

			setCurrentFiles(filteredFilesArray);
		} catch (error) {
			console.error(error);
		}
	};

	const handleRemoveAll = () => {
		setCurrentFiles([]);
		ref.current.files = null;
	};

	return (
		<>
			<UiInput
				className={className}
				isFileUpload
				errorMsgs={error ? [error] : []}
				id={id}
				label={label}
				description={description}
				required={required}
				disabled={disabled}>
				<input
					id={id}
					ref={ref}
					className='form-field__input'
					type={type}
					accept={accept}
					multiple={multiple}
					disabled={disabled}
					onChange={handleAdd}
				/>
				<span
					className={`form-field__label${
						disabled ? ' form-field__label--disabled' : ''
					}`}>
					<div className='form-field__icon'>
						<FontAwesomeIcon
							icon={faPaperclip}
							size='lg'
							fixedWidth
						/>
					</div>
					<span>Bestand(en) toevoegen</span>
				</span>
				<span
					className={`form-field__sub-label${
						disabled ? ' form-field__sub-label--disabled' : ''
					}`}>
					Sleep bestand(en) naar hier
				</span>
			</UiInput>
			{currentFiles.length !== 0 ? (
				<div className='form-field__upload-file-section'>
					{currentFiles.map((file, index) => (
						<div className='form-field__upload-file' key={index}>
							<div className='form-field__upload-file-label'>
								<FontAwesomeIcon icon={faFile} fixedWidth />
								<span>
									{file.name}
									{file.size
										? ` (${getFileSize(file.size)})`
										: ''}
								</span>
							</div>
							<button
								className='form-field__upload-file-remove-button'
								onClick={e => handleRemove(e, file)}>
								<FontAwesomeIcon icon={faTimes} fixedWidth />
							</button>
						</div>
					))}
					<div className='form-field__upload-remove-all'>
						<button
							className='form-field__upload-remove-all-button'
							onClick={handleRemoveAll}>
							<FontAwesomeIcon icon={faTrash} fixedWidth />
							<span>Verwijder alles</span>
						</button>
					</div>
				</div>
			) : null}
		</>
	);
};

Upload.defaultProps = {
	accept: '',
	description: '',
	disabled: false,
	label: '',
	multiple: false,
	name: '',
	required: false,
	type: 'file',
	maxSize: 10000000,
	value: [],
};

Upload.propTypes = {
	accept: string,
	description: string,
	disabled: bool,
	maxSize: number,
	label: string,
	multiple: bool,
	name: string.isRequired,
	required: bool,
	type: string,
	value: arrayOf(object),
	onChange: func.isRequired,
	onDelete: func,
};

export default Upload;
