import { ACTION_TYPES } from '../../../core/constants/action.constants/action.constants';

let defaultState = {
	subFeeds: [],
	subFeedsNextPage: 1,
	subFeedHasNextPage: true,
	subFeedIsNextPageLoading: false,
};

const loadSubFeeds = (state, { subFeedLoading }) => ({
	...state,
	subFeedIsNextPageLoading: subFeedLoading,
});

const loadSubFeedsCommit = (state, { data }) => {
	return {
		...state,
		subFeeds: [
			...state.subFeeds,
			...data.data.filter(x => !state.subFeeds.find(y => x.id === y.id)),
		],
		subFeedsNextPage: data.hasNextPage ? data.currentPage + 1 : null,
		subFeedHasNextPage: data.hasNextPage,
		subFeedIsNextPageLoading: false,
	};
};

export const subFeedReducer = (state = defaultState, { type, payload }) => {
	switch (type) {
		case ACTION_TYPES.SUB_FEEDS_LOAD:
			return loadSubFeeds(state, payload);
		case ACTION_TYPES.SUB_FEEDS_LOAD_COMMIT:
			return loadSubFeedsCommit(state, payload);
		default:
			return state;
	}
};
