import { Component } from 'react';
import * as ReactDOM from 'react-dom';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { array, bool, func, node, object, string } from 'prop-types';

import { ThemeContext } from '../../core/hooks/ThemeProvider';
import { Button } from '../Form';
import Image from '../Image/Image';

import './Modal.scss';

class Modal extends Component {
	constructor(props) {
		super(props);
		this.el = document.createElement('div');
		// this.modalRoot = document.getElementById('modal-root');
		this.el.classList.add('base');
	}

	// componentDidMount() {
	// 	this.modalRoot.appendChild(this.el);
	// }

	// componentWillUnmount() {
	// 	this.modalRoot.removeChild(this.el);
	// }

	renderModal() {
		const {
			hideHeader,
			show,
			close,
			large,
			medium,
			small,
			children,
			title,
			subTitle,
			picture,
			className,
			style,
			actions,
			cancel,
		} = this.props;

		if (!show) return null;

		document.onkeydown = function (evt) {
			if (evt.key == 'Escape') {
				close();
			}
		};

		return (
			<div className='modal'>
				<div
					className={`modal__content${large ? ' large' : ''}${
						medium ? ' medium' : ''
					}${small ? ' small' : ''}${
						className ? ` ${className}` : ''
					}`}
					style={style}>
					<section>
						{picture ? <Image picture={picture} shadow /> : null}

						<main>
							{hideHeader && hideHeader === true ? (
								<div className='modal__header close'>
									<button
										onClick={close}
										aria-label='sluiten'>
										<FontAwesomeIcon icon={faTimes} />
									</button>
								</div>
							) : (
								<div className='modal__header'>
									<h2>{title ? title : 'Modal window'}</h2>
									{subTitle ? <h3>{subTitle}</h3> : null}
									<button
										onClick={close}
										aria-label='sluiten'>
										<FontAwesomeIcon icon={faTimes} />
									</button>
								</div>
							)}

							<div className='modal__body'>{children}</div>
						</main>
					</section>

					<div className='modal__actions'>
						<div className='btn-group'>
							{cancel ? (
								<Button
									buttonSize='small'
									buttonStyle='secondary'
									onClick={close}
									label='Annuleren'
								/>
							) : (
								''
							)}

							{actions.map((action, i) => (
								<Button
									key={i}
									buttonSize='small'
									buttonStyle={action.buttonType ?? 'primary'}
									className={action.className}
									onClick={action.action}
									styles={action.styles}
									label={action.label}
									disabled={action.disabled}
								/>
							))}
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		return (
			<ThemeContext.Consumer>
				{({ theme }) => {
					this.el.classList.add(`theme--${theme}`);
					return ReactDOM.createPortal(this.renderModal(), this.el);
				}}
			</ThemeContext.Consumer>
		);
	}
}

Modal.propTypes = {
	close: func.isRequired,
	show: bool.isRequired,
	cancel: bool,
	large: bool,
	medium: bool,
	small: bool,
	title: string,
	subTitle: string,
	className: string,
	style: object,
	actions: array,
	children: node,
};

Modal.defaultProps = {
	large: false,
	medium: false,
	actions: [],
	cancel: true,
};

export default Modal;
