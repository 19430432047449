// To change an array of objects to an array that is compatible with React Select.
export const changeArrayPropertyNames = array => {
	//Zonder deze check crasht de pagina bij het laden omdat de tags nog niet zijn ingeladen.
	if (!array) {
		console.log('array is empty');
	} else {
		const newArray = array.map(function (row) {
			return { value: row.tag, label: row.tag };
		});
		return newArray;
	}
};

export function ParseNumbersToHaveDecimalSeperators(x) {
	x = x.toString().replace('.', ',');
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export function parseTimeToNumber(timeString) {
	if (timeString != timeString) {
		return 0;
	} else {
		if (timeString === '00:00') {
			return 0.0;
		} else {
			const [hours, minutes] = timeString.split(':').map(Number);
			const number = hours + minutes / 60;
			return number;
		}
	}
}

export function parseNumberToTime(number) {
	const hours = Math.floor(number);
	const minutes = Math.round((number - hours) * 60);
	const makeTwoDigits = number => number.toString().padStart(2, '0');
	return `${makeTwoDigits(hours)}:${makeTwoDigits(minutes)}`;
}
