export const AddEmployeesModal = ({
	feedback,
	medeWerkers,
	geselecteerdeMedewerkers,
	AddToSelectedEmployees,
}) => {
	const Departments = ['Finance', 'IT', 'RACS', 'UniPartners'];
	return (
		<section className='add-employee-modal'>
			{feedback != undefined ? (
				<p className='feedback'>{feedback}</p>
			) : null}
			{Departments.map(department => (
				<section className='department-container'>
					<h2>{department}</h2>
					{medeWerkers
						.filter(werker => werker.department === department)
						.map(item =>
							geselecteerdeMedewerkers.find(
								werker => werker === item,
							) != undefined ? null : (
								<p onClick={() => AddToSelectedEmployees(item)}>
									{item.firstName} {item.lastName}
								</p>
							),
						)}
				</section>
			))}
		</section>
	);
};
