import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {
	faImage,
	faPenToSquare,
	faPowerOff,
	faStar,
	faThumbtack,
	faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './WideListItem.scss';

export const WideListItem = ({
	type,
	title,
	slug,
	showIconToDelete,
	showIconToEdit,
	showIconToPublish,
	showPinIcon,
	showImageIcon,
	showImportantIcon,
	publishedAt,
	isImportant,
	children,
	setModal,
}) => {
	const GetSlug = () => {
		let mid_slug = '';
		if (type === 'evenement') {
			mid_slug = 'nieuw-evenement';
		} else if (type === 'opleiding') {
			mid_slug = 'nieuwe-opleiding';
		} else {
			mid_slug = 'nieuw-artikel';
		}
		return mid_slug;
	};
	//todo update dependancy array
	useEffect(() => {
		GetSlug();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<section className='widelist-item'>
			<h2>
				<span>{title}</span>
			</h2>
			<section className='widelist-item__icon-container'>
				{showIconToEdit ? (
					<NavLink to={`${GetSlug()}/${slug}`}>
						<FontAwesomeIcon
							icon={faPenToSquare}
							className='icon'
						/>
					</NavLink>
				) : null}
				{showImageIcon ? (
					<FontAwesomeIcon icon={faImage} className='icon' />
				) : null}
				{showPinIcon ? (
					<FontAwesomeIcon icon={faThumbtack} className='icon' />
				) : null}
				{showImportantIcon ? (
					isImportant === 1 ? (
						<FontAwesomeIcon
							icon={faStar}
							className='icon important'
						/>
					) : (
						<FontAwesomeIcon icon={faStar} className='icon' />
					)
				) : null}
				{showIconToPublish ? (
					publishedAt == undefined ? (
						<FontAwesomeIcon
							icon={faPowerOff}
							className='icon not-published'
							onClick={() => setModal('publish')}
						/>
					) : (
						<FontAwesomeIcon
							icon={faPowerOff}
							className='icon published'
							onClick={() => setModal('unpublish')}
						/>
					)
				) : null}
				{showIconToDelete ? (
					<FontAwesomeIcon
						icon={faTrash}
						className='icon'
						onClick={() => setModal('delete')}
					/>
				) : null}
			</section>
			{children}
		</section>
	);
};
