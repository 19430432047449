import { array, bool, func, number, string } from 'prop-types';

import UiInput from '../UiInput/UiInput';
import useFormInput from '../useFormInput';

const Textarea = ({
	name,
	label,
	description,
	value,
	placeholder,
	validate,
	required,
	readOnly,
	rows,
	disabled,
	validations,
	errors,
	onChange,
	className,
}) => {
	const { touched, focused, id, ...handlers } = useFormInput({
		name,
		validate,
		errors,
		onChange,
		validations,
	});

	return (
		<UiInput
			name={name}
			hasValue={value ? value.length > 0 : null}
			errorMsgs={touched ? errors : []}
			label={label}
			required={required}
			description={description}
			className={className}
			disabled={disabled}
			focused={focused}
			id={id}>
			<textarea
				rows={rows}
				name={name}
				id={id}
				value={value}
				placeholder={`${placeholder}${
					placeholder && required && !label ? ' *' : ''
				}`}
				{...handlers}
				readOnly={readOnly}
				disabled={disabled}
			/>
		</UiInput>
	);
};

Textarea.defaultProps = {
	label: '',
	placeholder: '',
	description: '',
	required: false,
	readOnly: false,
	disabled: false,
	validations: [],
	rows: 5,
	errors: [],
};

Textarea.propTypes = {
	name: string,
	label: string,
	placeholder: string,
	description: string,
	value: string,
	required: bool,
	readOnly: bool,
	disabled: bool,
	validations: array,
	errors: array,
	rows: number,
	onChange: func.isRequired,
	validate: func.isRequired,
};

export default Textarea;
