import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EditorState } from 'draft-js';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './ArticleAdd.scss';

const ArticleAdd = () => {
	const [editorState, setEditorState] = useState(EditorState.createEmpty());
	const [title, setTitle] = useState('');

	const onEditorStateChange = editorState => {
		setEditorState(editorState);
	};

	return (
		<div className='article-add-container'>
			<div className='content-and-settings'>
				<div className='content-section'>
					<h1>Entiteit toevoegen: Evenement</h1>
					<label htmlFor='title'>Titel:</label>
					<input
						type='text'
						id='title'
						value={title}
						onChange={e => setTitle(e.target.value)}
						placeholder='Voer titel in'
					/>
					<section>
						<label htmlFor='editor'>Inhoud:</label>
						<Editor
							editorState={editorState}
							wrapperClassName='wrapper-class'
							editorClassName='editor-class'
							toolbarClassName='toolbar-class'
							onEditorStateChange={onEditorStateChange}
						/>
					</section>
					<div>
						<FontAwesomeIcon
							icon={faTriangleExclamation}
							className='icon'
							fixedWidth
						/>
						<label htmlFor='important' className='small'>
							OPGELET
						</label>
						<label htmlFor='important' className='small'>
							De inhoud van dit bericht wordt via KetchUP en de
							nieuwsbrief binnen heel UniPartners gedeeld. Deel
							hier geen gevoelige informatie.
						</label>
					</div>
					<div className='event-booking-container'>
						<div className='calendar-container'>
							<label htmlFor='selectedDate'>
								Datum van de geselecteerde dag:
							</label>
						</div>

						<div className='booking-details'>
							<label htmlFor='selectedDate'>
								Datum van de geselecteerde dag:
							</label>
							<input
								type='text'
								id='selectedDate'
								name='selectedDate'
								placeholder='tot' // Note: This placeholder may not work as intended for showing between two values.
							/>
							<label className='small-text'>
								Maak start- en eindtijd gelijk voor een open
								einde.
							</label>

							<div className='checkbox-container'>
								<input
									type='checkbox'
									id='orderSandwiches'
									name='orderSandwiches'
								/>
								<label htmlFor='orderSandwiches'>
									Bestel broodjes
								</label>
							</div>

							<label htmlFor='reason'>Reden:</label>
							<textarea id='reason' name='reason' rows='4' />
							<label className='small-text'>
								Waarom moeten er geen broodjes besteld worden?
							</label>
						</div>
					</div>
				</div>
				<div className='settings-section'>
					<h2>Instellingen</h2>

					<label htmlFor='messageType'>Type bericht:</label>
					<select id='messageType'>
						<option value='event'>Evenement</option>
						<option value='article'>Artikel</option>
						<option value='training'>Opleiding</option>
					</select>

					<div>
						<input type='checkbox' id='important' />
						<label htmlFor='important'>Belangrijk</label>
						<label htmlFor='important' className='small'>
							Markeer het bericht in het overzicht
						</label>
					</div>

					<div>
						<input type='checkbox' id='pin' />
						<label htmlFor='pin'>Vastpinnen</label>
						<label htmlFor='pin' className='small'>
							Het bericht vastzetten aan de bovenkant
						</label>
					</div>

					<label htmlFor='tags'>Tags:</label>
					<select id='tags' placeholder='Selecteer je tags'>
						{/* Assuming dynamic tag options will be populated here */}
					</select>
					<label htmlFor='pin' className='small'>
						Voeg een tag toe door de tag in te typen en op enter te
						drukken
					</label>

					<h2>Evenement Instellingen</h2>

					<label htmlFor='tags'>Organisatoren</label>
					<select id='tags' placeholder='Selecteer de organizatoren'>
						{/* Assuming dynamic tag options will be populated here */}
					</select>

					<label htmlFor='tags'>Locatie</label>
					<select id='tags' placeholder='Selecteer de locatie'>
						{/* Assuming dynamic tag options will be populated here */}
					</select>
					<label htmlFor='pin' className='small'>
						Voeg een locatie toe door de locatie in te typen en op
						enter te drukken
					</label>

					<div>
						<input type='checkbox' id='important' />
						<label htmlFor='important'>
							Kan gebruik maken van opleidingsuren
						</label>
					</div>

					<label htmlFor='tags'>Aantal uren</label>
					<select id='tags' placeholder='Selecteer je tags'>
						{/* Assuming dynamic tag options will be populated here */}
					</select>
					<label htmlFor='pin' className='small'>
						Bovenstaande uren kunnen gebruikt worden als afwezigheid
					</label>

					<h2>Publiceren</h2>

					<div>
						<input type='checkbox' id='important' />
						<label htmlFor='important'>
							Het bericht publiceren
						</label>
					</div>

					<label htmlFor='tags'>Tags:</label>
					<input
						type='date'
						id='eventDate'
						placeholder='Selecteer een datum'
					/>
					<label htmlFor='pin' className='small'>
						Voeg een tag toe door de tag in te typen en op enter te
						drukken
					</label>

					<div>
						<input type='checkbox' id='important' />
						<label htmlFor='important'>Dringend</label>
						<label htmlFor='pin' className='small'>
							De nieuwsbrief zal meteen verzonden worden
						</label>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ArticleAdd;
