import { BrowserRouter } from 'react-router-dom';

import ErrorBoundary from './components/ErrorBoundary/ErrorBoundry';
import AdminRouter from './core/routers/AdminRouter/AdminRouter';

const App = () => (
	<BrowserRouter>
		<ErrorBoundary>
			<AdminRouter />
		</ErrorBoundary>
	</BrowserRouter>
);
export default App;
