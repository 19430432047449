//import { useSelector } from 'react-redux';

//import { removeToast } from '../../core/store/snackbar/snackbar.actions';

//import Toast from './Toast';

const Snackbar = () => {
	//  const { toasts } = useSelector(state => state.snackbarReducer);

	return <div className='toasts' id='toast' />;
};

export default Snackbar;
