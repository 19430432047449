import { Permissions_example } from '../../../../assets/JSON-examples/Permissions/All_Permissions_example';
import { Roles_Permissions_example } from '../../../../assets/JSON-examples/Permissions/All_Role_Permissions_example';
import { Roles_example } from '../../../../assets/JSON-examples/Permissions/All_Roles_example';

import './PermissionList.scss';

export const PermissionList = () => {
	return (
		<section className='permissions'>
			<section className='permissions__header'>
				<p className='name'>Naam</p>
				{Roles_Permissions_example.map((role, index) => (
					<p className='role' key={index}>
						{role.name}
					</p>
				))}
			</section>
			<section className='permissions__body'>
				{Permissions_example.map(permissie => (
					<section className='permissions__item' key={permissie.id}>
						<section className='description-container'>
							<p className='name'>{permissie.display_name}</p>
							<p className='description'>
								{permissie.description}
							</p>
						</section>

						{Roles_example.map(role => (
							<section
								className='checkbox-container'
								key={role.id}>
								<input type='checkbox' />
							</section>
						))}
					</section>
				))}
			</section>
		</section>
	);
};
