import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {
	faChalkboardUser,
	faChampagneGlasses,
	faGraduationCap,
	faMartiniGlassCitrus,
	faNewspaper,
	faSchool,
	faTags,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './dashboard.scss';

const Dashboard = () => {
	useEffect(() => {}, []);

	let exampleArray = [
		'Wim Goossens',
		'Arno Van Dooren',
		'Arne Goossens',
		'Kevin Goossens',
		'Jebbe Goossens',
	];

	return (
		<section className='dashboard-container'>
			<section className='dashboard-container__shortcuts-container'>
				<NavLink to='/nieuws/nieuw-artikel'>
					<button className='btn btn--primary'>
						Artikel Toevoegen
					</button>
				</NavLink>
				<NavLink to='/evenementen/nieuw-evenement'>
					<button className='btn btn--primary'>
						Evenement Toevoegen
					</button>
				</NavLink>
				<NavLink to='/opleidingen/nieuwe-opleiding'>
					<button className='btn btn--primary'>
						Opleiding Toevoegen
					</button>
				</NavLink>
			</section>

			<section className='dashboard-container__upcoming-container'>
				<section className='first-upcoming'>
					<h3>Mijn eerstvolgende event of opleiding</h3>
					<section className='first-upcoming__body'>
						<p className='label'>Datum</p>
						<p className='value'>21-10-2023</p>
						<p className='label'>Uur</p>
						<p className='value'>17:30-22:00</p>
						<p className='label'>Locatie</p>
						<p className='value'>Opleidingslokaal Theater</p>
						<p className='label'>Aanwezig</p>
						<p className='value'>18/45</p>
					</section>
				</section>

				<section className='next-upcoming'>
					{exampleArray.map((item, index) => (
						<section className='item' key={index}>
							<h3>Ander event </h3>
							<p className='value'>Datum: 21-10-2023</p>
						</section>
					))}
				</section>
			</section>

			<section className='dashboard-container__under-container'>
				<section className='dashboard-container__statistics-container'>
					<div class='dashboard-card card-participants'>
						<FontAwesomeIcon
							icon={faMartiniGlassCitrus}
							className='icon'
							fixedWidth
						/>
						<div class='text-content'>
							<h3>698 feestvierders</h3>
							<p>op 29 evenementen</p>
						</div>
					</div>
					<div class='dashboard-card card-participants'>
						<FontAwesomeIcon
							icon={faSchool}
							className='icon'
							fixedWidth
						/>
						<div class='text-content'>
							<h3>423 gevolgde lesuren</h3>
							<p>op 84 dagen</p>
						</div>
					</div>
					<div class='dashboard-card card-participants'>
						<FontAwesomeIcon
							icon={faChalkboardUser}
							className='icon'
							fixedWidth
						/>
						<div class='text-content'>
							<h3>65 lesgevers</h3>
							<p>waarvan 58 actief</p>
						</div>
					</div>
					<div class='dashboard-card card-participants'>
						<FontAwesomeIcon
							icon={faTags}
							className='icon'
							fixedWidth
						/>
						<div class='text-content'>
							<h3>24 getagged</h3>
							<p>UniPartners Group</p>
						</div>
					</div>
					<section class='first-upcoming-under'>
						<div class='header'>
							<FontAwesomeIcon
								icon={faGraduationCap}
								class='icon'
								fixedWidth
							/>
							<h3>Meeste Deelnemers</h3>
						</div>
						<div class='first-upcoming-under__body'>
							<p class='value'>1. Opleiding 1</p>
							<p class='value'>2. Opleiding 2</p>
							<p class='value'>3. Opleiding 3</p>
							<p class='value'>4. Opleiding 4</p>
							<p class='value'>5. Opleiding 5</p>
						</div>
					</section>

					<section class='first-upcoming-under'>
						<div class='header'>
							<FontAwesomeIcon
								icon={faChampagneGlasses}
								class='icon'
								fixedWidth
							/>
							<h3>Meeste Deelnemers</h3>
						</div>
						<div class='first-upcoming-under__body'>
							<p class='value'>1. Evenement 1</p>
							<p class='value'>2. Evenement 2</p>
							<p class='value'>3. Evenement 3</p>
							<p class='value'>4. Evenement 4</p>
							<p class='value'>5. Evenement 5</p>
						</div>
					</section>
				</section>
				<section class='dashboard-container__additional-container'>
					<div class='dashboard-card card-additional'>
						<div class='text-content'>
							<h3>Card Title 1</h3>
							<p>Card description 1</p>
						</div>
						<FontAwesomeIcon
							icon={faNewspaper}
							className='icon'
							fixedWidth
						/>
					</div>
					<div class='dashboard-card card-additional'>
						<div class='text-content'>
							<h3>Card Title 2</h3>
							<p>Card description 2</p>
						</div>
						<FontAwesomeIcon
							icon={faGraduationCap}
							className='icon'
							fixedWidth
						/>
					</div>
					<div class='dashboard-card card-additional'>
						<div class='text-content'>
							<h3>Card Title 3</h3>
							<p>Card description 3</p>
						</div>
						<FontAwesomeIcon
							icon={faChampagneGlasses}
							className='icon'
							fixedWidth
						/>
					</div>
				</section>
			</section>
		</section>
	);
};

export default Dashboard;
