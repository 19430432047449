import { bool, func, number, oneOfType, string } from 'prop-types';

import './Toggle.scss';

const Toggle = ({ value, disabled, error, onChange, className }) => {
	const handleClick = () => {
		if (!disabled) {
			onChange(!value);
		}
	};

	return (
		<div
			onClick={handleClick}
			className={`toggle ${className ? className : ''}${
				disabled ? ' disabled' : ''
			}${error ? ' error' : ''}`}>
			<span
				className={`toggle__switch toggle__switch--${
					value ? 'on' : 'off'
				}`}
			/>
		</div>
	);
};

Toggle.defaultProps = {
	value: false,
	disabled: false,
	error: false,
	className: '',
};

Toggle.propTypes = {
	value: oneOfType([string, bool, number]).isRequired,
	disabled: bool,
	error: bool,
	onChange: func.isRequired,
	className: string,
};

export default Toggle;
