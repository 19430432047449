import React, { useState } from 'react';
import { Calendar } from 'react-calendar';
import { Editor } from 'react-draft-wysiwyg';
import CreatableSelect from 'react-select/creatable';
import {
	faCalendarDays,
	faClock,
	faStar,
	faTrash,
	faUtensils,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Medewerkers_Example } from '../../../../assets/JSON-examples/Medewerkers/Medewerkers-example';
import { FileUploader } from '../../../../components/FileUploader/FileUploader';
import Modal from '../../../../components/Modal/Modal';
import { Selector } from '../../../../components/Selector/Selector';

import { AddEmployeesModal } from './Modals/AddEmployeesModal';
import { InputHourModal } from './Modals/InputHourModal';

import './AddNewItemPage.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const AddNewItemPage = ({
	pageTitle,
	geselecteerdeMedewerkers,
	setGeselecteerdeMedewerkers,
	title,
	setTitle,
	tekst,
	setTekst,
	volledigeDatum,
	setVolledigetDatum,
	organisatoren,
	setOrganisatoren,
	locatie,
	enableLocationAndTime,
	enableInvites,
	uploadedDocuments,
	uploadNewDocuments,
	links,
	setLinks,
	limietOpInschrijvingen,
	setLimietOpInschrijvingen,
	optieVoorOpleidingsUren,
	tags,
}) => {
	const medeWerkers = Medewerkers_Example;
	const optieOpleidingsUren = true;
	const [modal, setModal] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [searchTextOrganisatoren, setSearchTextOrganisatoren] = useState('');
	const [textLinks, setTextLinks] = useState('');
	const [selectedGroups, setSelectedGroups] = useState([]);
	const [selectedRoles, setSelectedRoles] = useState([]);
	const feedback = '';
	const [totalDate, setTotalDate] = useState();

	const AddToOrganisers = employee => {
		setOrganisatoren([...organisatoren, employee]);
	};
	const RemoveOrganisor = itemToRemove => {
		setOrganisatoren(organisatoren.filter(item => item !== itemToRemove));
	};
	const SelectEveryOne = () => {
		setGeselecteerdeMedewerkers(Medewerkers_Example);
	};
	const DeSelectEveryOne = () => {
		setGeselecteerdeMedewerkers([]);
		setSelectedGroups([]);
	};
	const AddToSelectedEmployees = employee => {
		setGeselecteerdeMedewerkers([...geselecteerdeMedewerkers, employee]);
	};
	const RemoveLink = itemToRemove => {
		setLinks(links.filter(item => item !== itemToRemove));
	};
	const RemoveDocument = itemToRemove => {
		uploadNewDocuments(
			uploadedDocuments.filter(item => item !== itemToRemove),
		);
	};
	const handleRemoveItem = itemToRemove => {
		setGeselecteerdeMedewerkers(
			geselecteerdeMedewerkers.filter(item => item !== itemToRemove),
		);
	};
	const AddSpecificGroupToSelected = department => {
		let tempArray = medeWerkers.filter(item =>
			geselecteerdeMedewerkers.includes(item) == true
				? null
				: item.department === department,
		);
		tempArray = tempArray.filter(
			(ele, ind) =>
				ind ===
				tempArray.findIndex(
					elem =>
						elem.firstName === ele.firstName && elem.id === ele.id,
				),
		);
		setSelectedGroups([...selectedGroups, department]);
		setGeselecteerdeMedewerkers(tempArray);
	};
	const RemoveGroupFromSelectedGroups = group => {
		let tempArray = selectedGroups.filter(item => item != group);
		setSelectedGroups(tempArray);
	};
	const DeselectSpecificGroupFromSelected = department => {
		let tempArray = geselecteerdeMedewerkers.filter(
			item => item.department != department,
		);
		setGeselecteerdeMedewerkers(tempArray);
		RemoveGroupFromSelectedGroups(department);
	};
	const AddSpecificRoleToSelected = role => {
		let tempArray = medeWerkers.filter(item =>
			geselecteerdeMedewerkers.includes(item) == true
				? null
				: item.coces.includes(role),
		);
		tempArray = tempArray.filter(
			(ele, ind) =>
				ind ===
				tempArray.findIndex(
					elem =>
						elem.firstName === ele.firstName && elem.id === ele.id,
				),
		);
		setSelectedRoles([...selectedRoles, role]);
		setGeselecteerdeMedewerkers(tempArray);
	};
	const RemoveRoleFromSelectedRoles = role => {
		let tempArray = selectedRoles.filter(item => item != role);
		setSelectedRoles(tempArray);
	};
	const DeselectRoleFromSelectedUsers = role => {
		let tempArray = geselecteerdeMedewerkers.filter(
			item => !item.coces.includes(role),
		);

		RemoveRoleFromSelectedRoles(role);
		setGeselecteerdeMedewerkers(tempArray);
	};
	const generateDateRange = (startDate, endDate) => {
		const dates = [];
		const currentDate = new Date(startDate);
		while (currentDate <= endDate) {
			dates.push(new Date(currentDate));
			currentDate.setDate(currentDate.getDate() + 1);
		}
		return dates;
	};
	const handleDateChange = e => {
		setVolledigetDatum(e);
		setTotalDate(generateDateRange(e[0], e[1]));
	};

	const locationOptions = [
		{
			label: 'Kantoor',
			value: 'Kardinaal Cardijnstraat 7 - 2840',
		},
		{
			label: 'Tuin UniPartners',
			value: 'Kardinaal Cardijnstraat 7 - 2840',
		},
		{
			label: 'Opleidingslokaal PC -  UniPartners',
			value: 'Kardinaal Cardijnstraat 7 - 2840',
		},
		{
			label: 'Opleidingslokaal Theater -  UniPartners',
			value: 'Kardinaal Cardijnstraat 7 - 2840',
		},
		{
			label: 'Vergaderzaal Orangerie -  UniPartners',
			value: 'Kardinaal Cardijnstraat 7 - 2840',
		},
		{
			label: 'Vergaderzaal 1e verdiep -  UniPartners',
			value: 'Kardinaal Cardijnstraat 7 - 2840',
		},
	];
	const date_options = {
		weekday: 'long',
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	};
	const colourStyles = {
		container: base => ({
			...base,
			flex: 1,
			textAlign: 'center',
			marginTop: '10px',
		}),
		menuList: base => ({
			...base,

			'::-webkit-scrollbar': {
				width: '0px',
				height: '0px',
			},
			'::-webkit-scrollbar-track': {
				background: '#f1f1f1',
			},
			'::-webkit-scrollbar-thumb': {
				background: '#00000',
			},
			'::-webkit-scrollbar-thumb:hover': {
				background: '#00000',
			},
		}),

		option: (styles, { isFocused }) => {
			return {
				...styles,
				backgroundColor: isFocused ? '#412F3B' : null,
				color: isFocused ? 'white' : '#412F3B',
				flex: 1,
			};
		},
		//De gekozen labels
		multiValue: styles => {
			return {
				...styles,
				backgroundColor: '#412F3B',
			};
		},
		//tekst van de gekozen labels
		multiValueLabel: styles => ({
			...styles,
			color: 'white',
		}),
	};

	return (
		<section className='add-item-container'>
			<h1>{pageTitle}</h1>

			<section className='add-item-container__general block-container'>
				<h2>Algemene informatie</h2>

				<section className='full_width_container title-container'>
					<label>Titel</label>
					<input
						type='text'
						placeholder='naam'
						value={title}
						onChange={e => setTitle(e.target.value)}
					/>
				</section>
				{organisatoren != undefined ? (
					<section className='full_width_container organisatoren-container'>
						<Selector
							title='organisatoren'
							array={medeWerkers}
							arraySelected={organisatoren}
							setArraySelected={setOrganisatoren}
							handleRemoveItem={RemoveOrganisor}
							handleAddItem={AddToOrganisers}
							searchText={searchTextOrganisatoren}
							setSearchText={setSearchTextOrganisatoren}
							hideShortcuts={true}
						/>
					</section>
				) : null}

				<section className='full_width_container textarea'>
					<label>Tekst</label>
					<Editor
						editorState={tekst}
						wrapperClassName='txt-wrapper'
						editorClassName='txt-editor'
						onEditorStateChange={setTekst}
					/>
				</section>

				<section className='full_width_container tags'>
					<label>Tags</label>
					<CreatableSelect
						isClearable
						placeholder={
							<i className='select-placeholder'>
								- Kies hier je tags -{' '}
							</i>
						}
						isMulti
						options={tags}
						styles={colourStyles}
						classNames={{
							//TODO  see how to clean up
							// eslint-disable-next-line no-unused-vars
							control: ({ isDisabled, isFocused }) => 'searchbar',
						}}
					/>
				</section>

				{optieVoorOpleidingsUren == true ? (
					<section className='full_width_container opleidingsuren-container'>
						<label>
							Mogelijkheid voor opleidingsuren te gebruiken.
						</label>
						<div>
							<input
								type='checkbox'
								placeholder='naam'
								onChange={() =>
									optieOpleidingsUren(!optieOpleidingsUren)
								}
							/>
							<p>Ja</p>
						</div>
						<div>
							<input
								type='checkbox'
								placeholder='naam'
								onChange={() =>
									optieOpleidingsUren(!optieOpleidingsUren)
								}
							/>
							<p>nee</p>
						</div>
					</section>
				) : null}
			</section>

			{enableLocationAndTime == false ? null : (
				<section className='add-item-container__timeAndLocation block-container'>
					<h2>Tijd en locatie</h2>
					{locatie && locatie !== '' ? (
						<p>
							niet Leeg || exact zelfde code als de REACT select
							maar andere uitkomst....
						</p>
					) : null}
					<section className='full_width_container locatie-container'>
						<label>Locatie</label>
						{!locatie && locatie !== '' ? (
							<CreatableSelect
								defaultValue={{
									value: 'niet leeg',
									label: 'niet leeg',
								}}
								isClearable
								options={locationOptions}
								styles={colourStyles}
							/>
						) : (
							<CreatableSelect
								defaultValue={{
									value: ' leeg',
									label: ' leeg',
								}}
								isClearable
								options={locationOptions}
								styles={colourStyles}
							/>
						)}
					</section>
					<section className='full_width_container laatste-dag-container'>
						<label>Laatste dag voor in te schrijven</label>
						<input type='date' />
					</section>
					<section className='full_width_container calendar-container'>
						<label>Tijd en uur</label>
						<Calendar
							value={volledigeDatum}
							onChange={e => handleDateChange(e)}
							selectRange={true}
							showDoubleView={false}
							showNeighboringMonth={false}
						/>

						{volledigeDatum && totalDate ? (
							<section className='gekozen-datum-container'>
								{totalDate.map((datum, index) => (
									<section className='row'>
										<label>Dag {index + 1}</label>

										<section className='row-body'>
											<div className='day-container subcontainer'>
												<FontAwesomeIcon
													icon={faCalendarDays}
													className='icon days'
												/>
												<p className='label'>
													{datum.toLocaleDateString(
														'nl',
														date_options,
													)}
												</p>
											</div>

											<div className='hour-container subcontainer'>
												<section className='hour-heading'>
													<FontAwesomeIcon
														icon={faClock}
														className='icon hours'
													/>
													<p className='label'>Uur</p>
													<p className='sidenote'>
														Voor een open einde,
														maak starttijd gelijk
														aan eindtijd.
													</p>
												</section>
												<section className='hour-body'>
													<div className='time-options'>
														<input type='time' />
													</div>
													<p>tot</p>
													<div className='time-options'>
														<input type='time' />
													</div>
												</section>
											</div>

											<div className='broodjes-container subcontainer'>
												<FontAwesomeIcon
													icon={faUtensils}
													className='icon'
												/>
												<p className='label'>
													Broodjes
												</p>
												<p className='sidenote'>
													Indien niet geef een reden
													waarom.
												</p>
												<div className='checkbox-container'>
													<input type='checkbox' />
													<p>of</p>
													<input
														type='text'
														placeholder='reden'
													/>
												</div>
											</div>
										</section>
									</section>
								))}
							</section>
						) : null}
					</section>
				</section>
			)}

			<section className='add-item-container__media block-container'>
				<h2>Media en bestanden</h2>
				<section className='full_width_container uploaded-container'>
					<label>Bestanden</label>
					{uploadedDocuments.length != 0 ? (
						<section className=''>
							{uploadedDocuments.map((document, index) => (
								<section className='uploaded-item' key={index}>
									<p>{document.name}</p>
									<div>
										<FontAwesomeIcon
											icon={faStar}
											className='icon'
										/>
										<FontAwesomeIcon
											icon={faTrash}
											className='icon'
											onClick={() =>
												RemoveDocument(document)
											}
										/>
									</div>
								</section>
							))}
						</section>
					) : null}
				</section>
				<FileUploader
					showDocumentsInContainer={false}
					uploadedDocuments={uploadedDocuments}
					uploadNewDocuments={uploadNewDocuments}
				/>

				<section className='full_width_container links'>
					<label>Links</label>
					<section className='half_width_container input-links'>
						<input
							type='url'
							placeholder='type hier jouw links in.'
							value={textLinks}
							pattern='https://.*'
							onChange={e => setTextLinks(e.target.value)}
							onKeyPress={event => {
								if (event.key === 'Enter') {
									if (textLinks === '') {
										alert('er is geen link ingevuld');
									} else {
										setLinks([...links, textLinks]);
										setTextLinks('');
									}
								}
							}}
						/>
					</section>
					{links && links.length != 0 ? (
						<section className='half_width_container selected-links'>
							<ol>
								{links.map(link => (
									<div>
										<li>{link}</li>
										<FontAwesomeIcon
											icon={faTrash}
											className='icon'
											onClick={() => RemoveLink(link)}
										/>
									</div>
								))}
							</ol>
						</section>
					) : null}
				</section>
			</section>

			{enableInvites === false ? null : (
				<section className='add-item-container__invites block-container'>
					<h2>Nodig mensen uit</h2>
					{limietOpInschrijvingen != undefined ? (
						<section className='full_width_container aantal-inscrhijvingen'>
							<label>Max aantal inschrijvers</label>
							<p className='sidenote'>
								Laat leeg voor geen limiet
							</p>
							<input
								type='number'
								value={limietOpInschrijvingen}
								onChange={e =>
									setLimietOpInschrijvingen(e.target.value)
								}
							/>
						</section>
					) : null}
					<Selector
						title='Medewerkers'
						array={medeWerkers}
						arraySelected={geselecteerdeMedewerkers}
						handleRemoveItem={handleRemoveItem}
						handleAddItem={AddToSelectedEmployees}
						searchText={searchText}
						setSearchText={setSearchText}
						SelectEveryOne={SelectEveryOne}
						DeSelectEveryOne={DeSelectEveryOne}
						AddSpecificGroupToSelected={AddSpecificGroupToSelected}
						DeselectSpecificGroupFromSelectedUsers={
							DeselectSpecificGroupFromSelected
						}
						selectedGroups={selectedGroups}
						setSelectedGroups={setSelectedGroups}
						setModal={setModal}
						RemoveGroupFromSelectedGroups={
							RemoveGroupFromSelectedGroups
						}
						AddSpecificRoleToSelected={AddSpecificRoleToSelected}
						selectedRoles={selectedRoles}
						setSelectedRoles={setSelectedRoles}
						DeselectRoleFromSelectedUsers={
							DeselectRoleFromSelectedUsers
						}
					/>

					{feedback != undefined ? (
						<p className='feedback'>{feedback}</p>
					) : null}
				</section>
			)}

			<section className='add-item-container__publish block-container'>
				<h2>Publiceren</h2>
				<p>
					Je kan kiezen om het nu te publiceren, een datum te kiezen
					om het later automatisch te publiceren of om het te bewaren
					en nog niet te publiceren.
				</p>
				<div>
					<button className='btn btn--primary'>Nu publiceren</button>
					<button className='btn btn--primary'>
						Later publiceren
					</button>
					<button className='btn btn--primary'>
						Concept opslaan
					</button>
					<button className='btn btn--secondary'>Annuleren</button>
				</div>
			</section>
			<Modal
				className='modal-invites'
				show={modal != false}
				close={() => setModal(false)}
				title={
					modal === 'overview-employees'
						? 'Medewerkers toevoegen'
						: modal === 'hour-input'
							? 'Geef een uur in'
							: 'er is iets misgegaan.'
				}>
				{modal === 'overview-employees' ? (
					<AddEmployeesModal
						medeWerkers={medeWerkers}
						geselecteerdeMedewerkers={geselecteerdeMedewerkers}
						handleRemoveItem={handleRemoveItem}
						AddToSelectedEmployees={AddToSelectedEmployees}
						searchText={searchText}
						feedback={feedback}
					/>
				) : modal === 'hour-input' ? (
					<InputHourModal />
				) : null}
			</Modal>
		</section>
	);
};

export default AddNewItemPage;
