export const Lesgever_example = [
	{
		id: 1,
		voornaam: 'Wim',
		achternaam: 'Van Dooren',
		mail: 'Wim.VanDooren@unipartners.org',
		CoCeToolToegang: true,
	},
	{
		id: 1,
		voornaam: 'Arno',
		achternaam: 'Moonens',
		mail: 'Wim.VanDooren@unipartners.org',
		CoCeToolToegang: true,
	},
	{
		id: 1,
		voornaam: 'Kevin',
		achternaam: 'Van Dooren',
		mail: 'Wim.VanDooren@unipartners.org',
		CoCeToolToegang: true,
	},
	{
		id: 1,
		voornaam: 'Arne',
		achternaam: 'Van Dooren',
		mail: 'Wim.VanDooren@unipartners.org',
		CoCeToolToegang: true,
	},
];
