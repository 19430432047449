import { ACTION_TYPES } from '../../../core/constants/action.constants/action.constants';
import { dispatchAction } from '../../../core/services/actions';
import { store } from '../store.config';

export const changeMode = mode => {
	const state = store.getState();
	const { authId } = state.authReducer;

	dispatchAction({
		type: ACTION_TYPES.THEME_MODE_UPDATE,
		payload: mode,
		meta: {
			action: {
				effect: {
					url: `/users/${authId}/set-theme`,
					method: 'post',
					body: { theme: mode },
				},
				commit: { type: ACTION_TYPES.THEME_MODE_UPDATE },
				rollback: {},
			},
			followup: [changeTheme],
		},
	});
};

export const changeTheme = () => {
	const state = store.getState();
	const { mode } = state.applicationReducer;

	dispatchAction({
		type: ACTION_TYPES.THEME_UPDATE,
		payload: mode,
	});
};
