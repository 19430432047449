import { ACTION_TYPES } from '../../../core/constants/action.constants/action.constants';
import { dispatchAction } from '../../../core/services/actions';

export const setInviteStatus = (id, type, accept) => {
	dispatchAction({
		meta: {
			action: {
				effect: {
					url: `/invitations/${type}/${id}`,
					method: 'post',
					body: { accept },
				},
				rollback: {},
			},
		},
	});
};

export const getInviteStatus = (id, type) => {
	dispatchAction({
		meta: {
			action: {
				effect: {
					url: `/invitations/${type}/${id}`,
					method: 'get',
				},
				commit: { type: ACTION_TYPES.INVITE_LOAD_COMMIT },
				rollback: {},
			},
		},
	});
};
